.darkBG {
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}
.model__section {
  position: fixed;
  top: 50%;
  left: 50%;

  width: 50%;
  border-radius: 1.25rem !important;
  transform: translate(-50%, -50%);
}
.model__content {
  margin-top: 5px;
}
.shop__button {
  border-radius: 50px;
  width: 200px;
  padding: 10px;
  background-color: #09c87f;
  color: white;
  border: 1px solid white;
  margin-top: 10px;
}

.shop__button:hover {
  transition: 0.4s ease-in-out;
  background-color: white;
  color: black;
  border: 1px solid #09c87f;
}
.text__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2.4rem;
}
.model__heading {
  font-size: 3.2rem;
  line-height: 1.2;
}
.model__closeicon {
  cursor: pointer;
  height: 2.4rem !important;
  width: 2.4rem !important;
  color: #fff;
}
.close__area {
  position: relative;
}
.model__closeicon {
  position: absolute;
  right: 50px;
  top: 30px;
}
@media (max-width: 767px) {
  .model__content {
    display: block;
  }
  .model__section {
    width: 100%;
  }
}
