.already__acoount {
  padding: 2.4rem 0;
  width: 100%;
}
.account__text {
  display: block;
  font-size: 1.6rem;
  font-weight: 500;
}
.login__link {
  font-size: 1.6rem;
  font-weight: 700;
  color: #fff;
  margin-left: 0.6rem;
  cursor: pointer;
}
.validation {
  margin: 0;
  text-align: left;
  font-size: 13px;
  margin-top: 10px;
  margin-bottom: 4px;
  color: rgb(252, 0, 0);
  /* line-height: 1.5; */
}
.progressbar {
  color: #df4b31 !important;
  width: 25px !important;
  height: 25px !important;
}
.progressactivate {
  color: #df4b31 !important;
  width: 16px !important;
  height: 16px !important;
}

.progressbar_login {
  color: #ffffff !important;
  width: 25px !important;
  height: 25px !important;
}
.social__login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 20px;
}
.googlebutn {
  background-color: #db4437;
  color: #fff;
  border: none;
  border-radius: 30px;
  padding: 10px;
}
.setlogin {
  cursor: pointer;
}
.resendotp {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.otploading {
  margin-top: 10px;
  cursor: pointer;
  font-weight: bold;
}
