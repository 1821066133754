.overlay_bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(237 237 237 / 30%);
  z-index: 99999;
}
.details {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99999;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 4px;
  padding: 36px 24px;
  width: 90%;
  max-width: 920px;
}
.modal_close_btn {
  position: absolute;
  right: -12px;
  top: -12px;
  width: 24px;
  height: 24px;
  background-color: #080a09;
  color: #fff;
  transition: 0.5s ease-in;
  border-radius: 50%;
  border: none;
}
.deletebutton {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  gap: 20px;
}
