:root {
  --primary: #de4b31;
  --secondary: #3f75ab;
  --primaryblack: #080a09;
}
body {
  touch-action: manipulation; /* Disable double-tap zoom */
  user-scalable: no; /* Prevent zooming on input focus */
}

.policycenter a:hover {
  color: #3f75ab;
}

#location_type,
#header_location_type {
  border: none !important;
}
.btn.show:active {
  border: none !important;
  border-color: var(--primary) !important;
}
.__extra {
  display: flex;
  flex-direction: column;
  gap: 9px;
}
.loader__container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.loader__container img {
  max-width: 30px;
}
.filter_footer {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.home_banner {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 600px !important;
  background-color: #584f4f;
  background-blend-mode: overlay;
}
.i_frame {
  text-align: center;
  /* position: relative;
  width: 100%;
  overflow: hidden; */
}
.hidden_class {
  display: none;
}
.image1 {
  background-image: url("../../assets/images/bannerimg1.jpg");
}
.image2 {
  background-image: url("../../assets/images/bannerimg2.jpg");
}
.image3 {
  background-image: url("../../assets/images/bannerimg3.jpg");
}
.banner_slider .slick-dots {
  justify-content: center;
  margin: 0;
  padding: 1rem 0;
  list-style-type: none;
  position: absolute;
  bottom: 0;
  /* left: 48%; */
  left: 0;
  z-index: 9;
}
.delete__confirmation {
  width: 50%;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;
}

.view_btn {
  margin-top: 30px;
}

.pro_slider .slick-dots {
  width: 99%;
}

.pack_content label {
  margin: 10px 0;
}
.float-right {
  float: right;
}
.float-left {
  float: left;
}
.clearfix {
  clear: both;
}
.leaflet-control-attribution {
  display: none !important;
}
.loader {
  display: flex;
  align-items: center;
  justify-content: center;
}
.location-drop {
  color: #584f4f;
}
.justify_class {
  display: flex;
  align-items: center;
  gap: 25px;
}
.searchitems {
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 4px 4px 8px 8px;

  transition: 0.5s ease-in;
  z-index: 10;
  position: relative;
  max-height: 500px;
  overflow-x: hidden;
  overflow-y: scroll;
}
.searchitems-mobile {
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: white;
  border-radius: 4px 4px 8px 8px;
  /* width: 63%; */
  /* margin-left: 28%; */
  transition: 0.5s ease-in;
  /* box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px; */
  z-index: 10;
  position: relative;
  max-height: 500px;
  overflow-x: hidden;
  overflow-y: scroll;
}
.search__item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  margin-bottom: 10px;
  transition: 0.5s ease-in;
}
.search__item a {
  transition: 0.5s ease-in;
}

.search__item a:hover {
  color: var(--primary) !important;
}
.search-item__image {
  width: 50px;
  border-radius: 50%;
  height: 50px;
}
a {
  text-decoration: none;
  color: black;
}
.search__content p {
  margin: 0px;
  line-height: 1.5;
}
.search__content .academy {
  font-size: 16px;
  font-weight: 500;
}
.search__content p:nth-child(2) {
  font-size: 14px;
  white-space: normal;
}
.no-content {
  text-align: center;
}

.home_search.header_search {
  max-width: 750px;
  margin: auto;
}
/* .sticky .navbar-nav.mb-wi,
.header_shad .navbar-nav.mb-wi {
  width: 300px;
} */
.sticky .header_search.wi_300,
.header_shad .header_search.wi_300 {
  width: 450px;
}

.nonslick {
  display: flex;
}
.nonslick .sportsbanner {
  width: 33.333%;
}
.subpage__search {
  position: absolute;
  left: 0;
  right: 0;
  top: 49px;
}
.header_search {
  position: relative;
}
.subpage__search .searchitems {
  width: 100%;
  margin-left: 0px;
}
.navbar .header_search .dropdown-toggle:after {
  left: auto;
  right: 8px;
}
#location_type {
  border-right: 1px solid #cfcfcf !important;
  border-radius: 0;
  padding-right: 20px;
  width: 100%;
}
#location_type .input-mini {
  border: none !important;
}
/* .header_search.home_search .dropdown-toggle::after {
  left: auto;
  right: 6px;
} */
#search_type {
  width: 100%;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
.slide_box {
  min-height: 540px;
}
.blue-line {
  border-bottom: 1px solid blue;
}
.buttons-login {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.back-btn {
  cursor: pointer;
}
.sportsbanner {
  cursor: pointer;
}
.overlay-sidebar {
  height: 100%;
  width: 0%;
  background-color: #00000080;
  position: fixed;
  top: 0;
  left: 0;
  right: -80px;
  bottom: 0;
  z-index: 9999;
}
.overlay-sidebar.sidebar {
  width: 100%;
  right: 0;
}
.cart-option-head {
  text-align: center;
  margin-bottom: 16px;
}
.cart-options {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.cate_left {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.pack__info {
  display: flex;
  flex-direction: column;
  gap: 3px;
  margin-top: 5px;
}
.strong_cls {
  font-weight: bold;
}
.tooltip-inner {
  max-width: 10px !important;
  width: 30px !important;
}
.toolpack + .tooltip > .tooltip-inner {
  background-color: #f00 !important;
}
.search__content {
  text-align: left;
}
.mbl_searchbar_ .dropdown-toggle::after {
  left: auto;
  position: absolute;
  right: 7px;
  top: 21px;
}
.blog_by {
  cursor: pointer;
}
.blog_slide {
  position: relative;
}
.blog_slider {
  overflow: hidden;
}
.blog_content {
  align-items: center;
}
@media (max-width: 425px) {
  .sort_div h6 {
    font-size: 10px;
  }
  #progressbar li {
    width: 100% !important;
  }
  #progressbar {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 150px;
    gap: 9px;
  }
  #progressbar li:first-child {
    width: 100% !important;
  }
  #progressbar li.active:before {
    left: 44% !important;
  }
  .react-datepicker__month-container {
    float: none !important;
  }
  .react-datepicker {
    width: 100%;
  }
  .terms-head {
    font-size: 12px;
  }
  .cmts_form {
    display: flex;
    flex-direction: column;
  }
  .empty-component {
    font-size: 10px;
  }
  .blog_content_banner {
    flex-direction: column !important;
    align-items: flex-start !important;
    gap: 0px !important;
  }
}

@media (max-width: 1400px) {
  .cmts_form label {
    display: none;
  }
}
@media (max-width: 1199px) {
  #progressbar li {
    font-size: 14px;
  }
  .sportcart {
    font-size: 10px;
  }
}
@media (max-width: 1199px) and (min-width: 992px) {
  #lblCartCount {
    position: absolute;
  }
}

@media only screen and (max-width: 999px) and (min-width: 768px) {
  .footer_widget .widget_2 a {
    font-size: 12px;
  }
  .widget_3 h6 a {
    font-size: 12px;
  }
  .social_icon {
    font-size: 18px;
  }
}

@media (max-width: 991px) {
  .profile_dropdown .dropdown-toggle::after {
    display: none;
  }
  .searchitems {
    width: 100%;
    margin-left: 0px;
  }
  .home_search.header_search {
    background-color: transparent;
  }
  .home_search.header_search .mbl_searchbar {
    background-color: #fff;
    border-radius: 8px;
    padding: 5px 10px !important;
    margin-bottom: 15px;
  }
  #location_type {
    border: none !important;
  }
  .header_search.home_search .dropdown-toggle::after {
    left: auto;
    right: 8px;
  }
}

@media (max-width: 1199px) and (min-width: 992px) {
  .sticky .header_search.wi_300,
  .header_shad .header_search.wi_300 {
    width: 360px;
  }
  /* .sticky .navbar-nav.mb-wi,
  .header_shad .navbar-nav.mb-wi {
    width: 250px;
  } */
}
@media (max-width: 1400px) {
  #Datepicker1 .react-datepicker__day,
  #Datepicker1 .react-datepicker__day-name {
    width: 30px;
  }
}
@media (max-width: 1199px) {
  #Datepicker1 .react-datepicker__day,
  #Datepicker1 .react-datepicker__day-name {
    width: 25px;
  }
}
@media (max-width: 767px) {
  #progressbar li {
    font-size: 13px;
  }
  .msfform .form-card {
    min-height: 475px;
  }
}
@media (max-width: 500px) {
  #progressbar li {
    font-size: 11px;
  }
}

@media (max-width: 1300px) {
  .blog_content {
    flex-direction: column !important;
    align-items: flex-start !important;
    gap: 0px !important;
  }
}

/*-------------Profile Page styling-------------*/
.profile_ip {
  border-radius: 0;
  color: #000;
}
.previewlabel,
.pro_btn {
  background-color: var(--secondary);
  color: #fff;
  border: 1px solid var(--secondary);
}
.previewlabel:hover,
.pro_btn:hover {
  background-color: #fff;
  color: var(--secondary);
}
.profilepreview {
  width: 110px;
}
.fmem_profile {
  width: 165px;
  height: 165px;
  object-fit: cover;
  object-position: center;
}
.mem_del,
.mem_edit {
  background-color: var(--primary);
  border-color: var(--primary);
  font-size: 16px;
  height: 35px;
  width: 35px;
  line-height: 35px;
  color: #fff;
}
.mem_del:hover,
.mem_edit:hover {
  background-color: var(--primary);
  color: white;
}
.add_profile {
  background-color: var(--primary);
  width: 165px;
  height: 165px;
  line-height: 15;
}
.fmem_box .font_call {
  color: var(--primary);
}
.pluscss {
  --b: 4px;
  width: 80px;
  aspect-ratio: 1;
  display: inline-block;
  border: none;
  background: conic-gradient(
      from 90deg at var(--b) var(--b),
      transparent 90deg,
      #fff 0
    )
    calc(100% + var(--b) / 2) calc(100% + var(--b) / 2) / calc(50% + var(--b))
    calc(50% + var(--b));
}
.member_modal.modal {
  padding: 0px !important;
}
.member_modal.modal .modal-lg {
  max-width: 80%;
  margin-top: 90px;
}

/*-------------After login styling-------------*/
.head_profile {
  height: 40px;
  object-fit: cover;
  object-position: center;
}
.profile_dropdown .dropdown-toggle::after {
  vertical-align: 2px;
  border-top-color: var(--primary);
  border-width: 6px;
}
.profile_dropdown .btn span {
  color: #3f76ac;
  font-weight: 600;
}
.profile_dropdown .dropdown-menu {
  box-shadow: 0px 8px 4px 4px #00000040;
  border: 1px solid #f3f3f3;
  border-radius: 0;
  right: 0;
  left: auto;
}
.profile_dropdown .dropdown-item:not(:last-child) {
  border-bottom: 1px solid #d2d2d2;
}
.dropdown-toggle:active,
.btn.show {
  border-color: #fff !important;
}
.afterlogin {
  display: flex;
  align-items: center;
  gap: 10px;
}

@media (max-width: 1183px) {
  .member_modal.modal .modal-lg {
    max-width: 98%;
  }
}
@media (max-width: 991px) {
  .member_modal.modal .modal-lg {
    margin-top: 75px;
  }
}
@media (max-width: 575px) {
  .pack__info {
    font-size: 10px;
  }
  .profile_page .log_label {
    margin-bottom: 5px;
  }
  .profile_page .font_call {
    font-size: 23px;
  }
}
.flex-banner {
  display: flex;
  align-items: center;
  justify-content: center;
}
.backIcon {
  cursor: pointer;
}
.text_dec h6 {
  display: flex;
  align-items: center;
  gap: 20px;
}
.leaflet-container {
  z-index: 0 !important;
}
.contact_icon {
  display: flex !important;
  align-items: center;
  justify-content: center;
}
.commonbtn {
  border: navajowhite;
  padding: 10px;
  color: #fff;
  background-color: var(--primary);
  border-color: var(--primary);
  border-radius: 0;
  font-size: 20px;
  width: 150px;
}
.remove {
  display: none;
  transition: 0.5s ease-in-out;
}
.addons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;
}
.checkout-button {
  border: navajowhite;
  padding: 10px;
  color: #fff;
  background-color: var(--primary);
  border-color: #3c3b3a;
  border-radius: 0;
  font-size: 20px;

  width: 50%;
  border-radius: 50px;
  margin: auto;
  margin-top: 20px;
}
.terms-check {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-size: 18px;
}
.terms-head {
  cursor: pointer;
}
.cart__total {
  display: flex;
  flex-direction: column;
  margin-top: 23px;
}
.accordion-button {
  background-color: #f9f9f9;
}
.sportcart {
  display: flex;
  justify-content: space-between;
  gap: 3px;
}
.loading {
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 100%;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: -webkit-radial-gradient(
    rgb(255 255 255 / 80%),
    rgb(255 255 255 / 80%)
  );
}
.loader {
  display: inline-block;
  width: 50px;
  height: 50px;
  border: 5px solid rgb(255 1 1 / 30%);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
}
.no-comments {
  box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;
  padding: 20px;
}
.cart-icon {
  font-size: 24px !important;
  color: #fff;
}
.cart-icon-1 {
  font-size: 25px !important;
}
.terms-sections {
  height: 300px;
  overflow: scroll;
}

.badge {
  padding-left: 9px;
  padding-right: 9px;
  -webkit-border-radius: 9px;
  -moz-border-radius: 9px;
  border-radius: 9px;
}

.label-warning[href],
.badge-warning[href] {
  background-color: #c67605;
}
#lblCartCount {
  font-size: 11px;
  background: var(--primary);
  color: #fff;
  padding: 1px 4px;
  vertical-align: top;
  margin-left: -10px;
  position: absolute;
  top: 1px;
  right: 40px;
}
.blog_col_image {
  transition: 0.4s;
  margin-bottom: 0;
}
.blog_main:hover .blog_col_image {
  transform: scale(1.1);
}

.sticky.trans_header .cart-icon {
  color: #bcbabb;
}
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: var(--primary) !important;
  padding: 0px !important;
  margin: 0px;
}
.css-13xfq8m-MuiTabPanel-root {
  padding: 3px !important;
}
.switch-items {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: stretch;
}
.share-icons {
  display: flex;
  gap: 10px;
}
.aligncenter {
  align-items: center;
  justify-content: center;
}
#lblCartCount-mobile {
  font-size: 11px;
  background: var(--primary);
  color: #fff;
  padding: 1px 4px;
  position: absolute;
  top: 0px;
  right: 13px;
}
.price__info {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 150px;
  height: 150px;
}
.align_right {
  text-align: right;
}
.align_center {
  text-align: center;
}
.delete_icon {
  cursor: pointer;
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
.slot-items {
  padding: 1rem 1rem 0 1rem;
}

.header_search .dropdown-menu {
  left: -6px !important;
  width: 450px;
}
/* .searchitems {
  margin-left: 40%;
  width: auto;
  top: 8px;
} */
.filter_footer {
  z-index: 5;
}
.search_dropdown.dropdown-toggle::after,
.sticky_search_dropdown::after {
  content: none;
}
.header_search.home_search .search_dropdown .dropdown-menu {
  width: auto;
  right: -12px !important;
}
.header_search .sticky_search_dropdown .dropdown-menu {
  left: auto !important;
  right: -8px;
}
.msfform .step3 {
  max-height: 420px;
  overflow-x: hidden;
  padding: 10px;
}
.ReactModal__Overlay {
  z-index: 9999 !important;
}
.btn#dropdownMenuButton {
  border: none !important;
}
.header_search.home_search
  #location_type
  .dropdown-menu[data-popper-placement="bottom-start"] {
  width: auto;
  right: 8px !important;
  left: 1px !important;
  transform: translate(0px) !important;
  top: 47px !important;
}
.header_search.home_search
  #location_type
  .dropdown-menu[data-popper-placement="top-start"] {
  width: auto;
  right: 8px !important;
  left: 1px !important;
  transform: translate(0px) !important;
  bottom: 47px !important;
}
.header_search.home_search
  #location_type
  .dropdown-menu[data-popper-placement="bottom-end"] {
  transform: translate(0px) !important;
  width: auto;
  right: 8px !important;
  left: 1px !important;
  transform: translate(0px) !important;
  top: 47px !important;
}
.header_search.home_search
  #location_type
  .dropdown-menu[data-popper-placement="top-end"] {
  transform: translate(0px) !important;
  width: auto;
  right: 8px !important;
  left: 1px !important;
  transform: translate(0px) !important;
  bottom: 47px !important;
}
.home_search .mbl_searchbar {
  position: relative;
}
.home_search .mbl_searchbar #location_type {
  position: initial;
}
@media (max-width: 1399px) and (min-width: 1200px) {
  .header_search .dropdown-menu {
    width: 450px;
  }
  .header_shad .sticky_search_dropdown .dropdown-menu,
  .header_shad #header_location_type .dropdown-menu {
    width: 431px;
  }
}
@media (max-width: 1399px) and (min-width: 992px) {
  #nav-values.nav_bg .nav-link,
  .sticky #nav-values .nav-link {
    padding: 0;
  }
}
@media (max-width: 1199px) and (min-width: 992px) {
  .header_search .dropdown-menu {
    width: 360px;
  }
  .header_shad .sticky_search_dropdown .dropdown-menu,
  .header_shad #header_location_type .dropdown-menu {
    width: 354px;
  }
}
@media (min-width: 992px) {
  .header_shad.mymenu {
    z-index: 1;
    position: relative;
  }
}
@media (max-width: 991px) {
  .header_search.home_search .search_dropdown .dropdown-menu {
    left: -8px !important;
  }
  .header_search.home_search #location_type .dropdown-menu {
    right: 0px !important;
  }
  .header_search.home_search
    #location_type
    .dropdown-menu[data-popper-placement="top-start"],
  .header_search.home_search
    #location_type
    .dropdown-menu[data-popper-placement="bottom-end"],
  .header_search.home_search
    #location_type
    .dropdown-menu[data-popper-placement="bottom-start"],
  .header_search.home_search
    #location_type
    .dropdown-menu[data-popper-placement="top-end"] {
    right: 0px !important;
  }
}
.coach_slide {
  border-radius: 15px;
  min-height: 430px;
  overflow: hidden;
}
.coach_slide .cate_img {
  border-radius: 15px 15px 0 0;
  height: 180px;
  transform: translate3d(0px, 0px, 0px);
  transition: 0.2s transform;
}
.coach_slide .cate_img:hover {
  transform: scale(1.2, 1.2);
}
.coach_top {
  overflow: hidden;
}
.coach_badge {
  background: var(--primary);
  padding: 2px 15px;
  border-radius: 20px;
  color: #fff;
  letter-spacing: 0.8px;
}
.coach_bottom {
  background-color: #fff;
  border-radius: 0 0 15px 15px;
}
.coach_hr {
  background: linear-gradient(to right, var(--primary) 0%, #fff 100%);
  height: 2px;
  border: none;
  opacity: 1;
}
.twitter_div {
  display: flex;
  align-items: center;

  gap: 20px;
}
.twitter_div p {
  margin: 0 !important;
}
.apple_info {
  text-align: justify;
  margin-top: 6px;
}

.sportsbutton {
  background-color: var(--primary);
  color: #ffff;
  padding: 20px;
  margin: 8px 0;
  border: none;
  cursor: pointer;
  width: 100%;
  border-radius: 10px;
}
.common-btn {
  background-color: var(--primary);
  color: #ffff;
  padding: 7px;
  margin: 8px 0;
  border: none;
  cursor: pointer;
  /* width: 100%; */
  border-radius: 10px;
}
.common-btn .backIcon {
  cursor: pointer;
  color: white;
}
.createclr {
  color: var(--primary);
  text-decoration: none;
  cursor: pointer;
}
.createclr:hover {
  color: var(--primary);
}
.loginimg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.lettersize {
  font-size: 16px;
}
.loginadjust {
  width: 33%;
  margin-left: auto;
  margin-right: auto;
}
.loginadjust hr {
  width: 30px;
  border: 1px solid black;
  opacity: 1;
}
.form_input {
  padding: 0;
  margin-top: 27px;
}
.form_input input {
  padding: 20px;
}
.policycenter {
  text-decoration: none;
  color: #000;
  font-weight: 600;
}
.sideimg {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
  margin-right: 0;
  margin-left: auto;
}
.sidepage {
  padding: 0;
}
.totalpage {
  max-width: 90%;
  margin-left: auto;
  margin-right: 0;
  padding: 0;
}
.google_form .fa-facebook {
  color: #2a4478;
  font-size: 25px;
  padding: 9px 20px 12px 9px;
  border-right: 1px solid #2a4478;
}
.fb_btn {
  color: white;
  display: block;
  margin: auto;
  text-align: center;
  background-color: var(--secondary);
}
.google_form .fa-google {
  border-right: 1px solid #ab2518;
  color: #ab2518;
  font-size: 25px;
}
.google_form .fa-apple {
  font-size: 25px;
  border-right: 1px solid #ffffff;
  padding: 7px 20px 12px 4px;
}
.google_btn {
  color: white;
  display: block;
  text-align: center;
  margin: auto;
  background-color: var(--primary);
}
.apple_btn {
  color: white;
  display: block;
  text-align: center;
  margin: auto;
  background-color: var(--primaryblack);
}
.buttons {
  display: flex;
  justify-content: space-around;
}
.orbtn {
  margin-top: 12px;
}
.orbtn p {
  margin-bottom: 6px;
}
@media (max-width: 767px) {
  .loginadjust {
    width: 100%;
  }
  .totalpage {
    margin: auto;
  }
  .sideimg {
    width: 70px;
    height: 70px;
  }
  .sidepage {
    width: 50%;
  }
  .logoimg {
    width: 50%;
  }
  .loginimg {
    display: none;
  }
  .buttons {
    display: block;
  }
  .form_input input {
    padding: 15px;
  }
  .sportsbutton {
    padding: 15px;
  }
  .form_input {
    margin-top: 10px;
  }
  .terms_links ul {
    flex-wrap: wrap;
  }

  .terms_links ul li:nth-child(3):before {
    content: "";
  }
  .terms_links ul li:nth-child(5):before {
    content: "";
  }
}
@media (min-width: 768px) and (max-width: 1270px) {
  .buttons {
    display: block;
  }
  .totalpage {
    max-width: 94%;
  }
  .loginadjust {
    width: 42%;
  }
}
.google_btn.btn:hover {
  color: white;
  background-color: var(--primary);
}
.apple_btn.btn:hover {
  color: white;
  background-color: var(--primaryblack);
}
.fb_btn.btn:hover {
  color: white;
  background-color: var(--secondary);
}
.lform {
  margin: 0;
  padding: 0;
}
.form_input_profile input {
  padding: 16px;
}
.form_input_profile select {
  padding: 16px;
}
.terms_links ul {
  list-style-type: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.terms_links ul :not(:first-child)::before {
  content: "|";
  margin-right: 10px;
}

@media (min-width: 768px) and (max-width: 992px) {
  .form_input input {
    padding: 10px;
  }
  .signtext {
    padding-left: 0;
    font-size: 9px;
  }
  .sidepage {
    width: 100%;
  }
  .sportsbutton {
    padding: 10px;
    margin: 13px 0;
  }
  .google_form .fa-facebook {
    padding: 6px 6px 5px 6px;
  }
  .google_form .fa-google {
    padding: 5px 6px 5px 6px;
  }
  .form_input {
    margin-top: 5px;
  }
  .totalpage {
    max-width: 95%;
  }
  .orbtn {
    margin-top: 0;
  }
}
@media (min-width: 1491px) and (max-width: 1739px) {
  .loginadjust {
    width: 40%;
  }
  .google_form .fa-facebook {
    padding: 9px 20px 12px 9px;
  }
}
@media (min-width: 1340px) and (max-width: 1490px) {
  .totalpage {
    max-width: 90%;
  }
  .loginadjust {
    width: 43%;
  }
}
@media (min-width: 992px) and (max-width: 1270px) {
  .sidepage {
    width: 100%;
  }
  .signtext {
    font-size: 12px;
  }
}
@media (min-width: 1270px) and (max-width: 1340px) {
  .loginadjust {
    width: 47%;
  }
}
@media (min-width: 360px) and (max-width: 575px) {
  .footer_widget {
    max-width: 90%;
    margin: auto;
  }
}
@media (min-width: 320px) and (max-width: 575px) {
  .footer_link_text {
    text-align: center !important;
  }
  .footer_link_text h4 {
    font-size: 16px;
  }
}

@media (min-width: 575px) and (max-width: 768px) {
  .footer_link_text {
    text-align: center !important;
  }
  .footer_link_text h4 {
    font-size: 18px;
  }
}

@media (min-width: 768px) and (max-width: 972px) {
  .loginfacebook {
    padding: 1rem !important;
  }
  .footer_link_text h4 {
    font-size: 18px;
  }
}

@media (min-width: 1340px) and (max-width: 1355px) {
  .fb_btn,
  .google_btn {
    font-size: 15px !important;
  }
}

@media (max-width: 767px) {
  .blog_full {
    height: 230px;
  }
  .contact_banner {
    height: 230px !important;
  }
}

/* TODAY */

.blog_content {
  align-items: baseline;
}

.google_form .fa-facebook {
  color: #ffffff;
}
.google_form .fa-google {
  color: #ffffff;
  padding: 6px 16px 6px 12px;
}
@media (max-width: 1200px) {
  .right_label {
    flex-direction: column !important;
  }
  .single_blog_info {
    padding: 0px 2px 12px 2px !important;
  }
}

.dropdown-menu .dropdown-item:active {
  background-color: var(--secondary) !important;
}

.css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked,
.primary_clr {
  color: var(--primary) !important;
}
.css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked
  + .MuiSwitch-track {
  background-color: var(--primary) !important;
}
.accordion-button:not(.collapsed) {
  color: var(--secondary) !important;
}
.secondary_clr,
.secondary_clr:hover {
  background-color: var(--secondary) !important;
  color: white;
}
.primary_clr_background,
.primary_clr_background:hover {
  background-color: var(--primary) !important;
  color: white;
}
@media (min-width: 992px) and (max-width: 1200px) {
  .cate_left {
    flex-direction: column;
    align-items: baseline;
  }
}

.comment_btn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 9px;
}
.comment_btn button {
  padding: 10px;
  color: #fff;

  margin-top: 10px;
}
.not_buyed {
  background-color: grey;
  border: 1px solid grey;
}
.buyed {
  background-color: var(--primary);
  border: 1px solid var(--primary);
}
.user_not_buyed {
  font-size: 21px !important;
  color: gray;
  cursor: pointer;
}
.edit_comment {
  cursor: pointer;
  font-size: 12px !important;
}
@media (max-width: 992px) {
  .single_blog_info {
    justify-content: flex-start !important;
  }
  .right_label .blog_heading {
    font-size: 24px !important;
  }
}

@media (max-width: 768px) {
  .i_frame {
    overflow: scroll;
  }
}

@media (max-width: 992px) {
  .hidden_class {
    display: block;
  }
}
/* footer appstore links */

.app_store_link {
  background-color: rgb(247 247 247);
  padding: 20px 0;
}
.footer_app_link {
  font-size: 20px;
}
.footer_link_text {
  text-align: left;
}
.app_link_icons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.whats-app {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 25px;
  left: 15px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}

.my-float {
  margin-top: 16px;
}

.chat_bot {
  position: fixed;
  right: 15px;
  bottom: 32px;
  z-index: 3;
  width: 160px;
}
.bot_button {
  background: #2c2c2d;
  cursor: pointer;
  border: transparent;
  color: #fff;
  height: 50px;
  border-radius: 10px;
  opacity: 1;
  filter: opacity(1);
  transition: all 0.3s ease-in;
  -webkit-transition: all 0.3s ease-in;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
}
.botbtn_text {
  color: white;
  font-weight: 700;
  padding-top: 15px;
  padding-left: 60px;
}
.botbtn_text a {
  color: white;
}
.chat_imgcontainer {
  position: absolute;
  top: -5px;
  left: -10px;
}
.chatbot_img {
  border-radius: 100%;
  width: 62px;
  height: 62px;
  border: 5px solid #2c2c2d;
  object-fit: cover;
  object-position: center;
  box-shadow: 1px 2px 7px rgba(0, 0, 0, 0.34);
}

@media (max-width: 992px) {
  .chat_bot,
  .whats-app {
    display: none;
  }
}

/* popup calender */
.react-calendar {
  /* width: 80%; */
  max-width: 100%;
  margin: auto;
  background: white;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
}
.calendar_head {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  margin-top: 25px;
  margin-bottom: 30px;
}
.react-calendar__navigation__label__labelText--from {
  font-size: 24px;
  font-weight: 600;
  color: #585757;
  background-color: unset;
}
.react-calendar--doubleView {
  width: 700px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-calendar button {
  margin: 0;
  border: 1px solid #e8e8e8;
  outline: none;
}

.react-calendar button:enabled:hover {
  cursor: pointer;
}

.react-calendar__navigation {
  display: flex;
  height: 44px;
  margin-bottom: 1em;
  align-items: baseline;
  position: relative;
  z-index: 9;
}

.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}

.react-calendar__navigation button:disabled {
  background-color: unset;
  border: unset;
}
.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}

.react-calendar__month-view__weekdays__weekday {
  padding: 13px 0 13px 10px;
  border: 1px solid #e8e8e8;
  text-align: start;
  color: #969696;
}
.react-calendar__tile.react-calendar__month-view__days__day {
  position: relative;
  padding: 12px;
  color: #969696;
  text-align: start;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  font-weight: bold;
}

.react-calendar__month-view__days__day--weekend {
  color: #d10000;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #969696;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}

.react-calendar__tile {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 135px;
  background: none;
  text-align: center;
  line-height: 16px;
}

.react-calendar__tile:disabled {
  background-color: white;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
}

.react-calendar__tile--hasActive {
  background: #76baff;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}

.react-calendar__tile--active {
  background: #f4faff;
  color: white;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: lightblue;
}
.react-calendar__tile--active:enabled:focus {
  background: #f4faff;
}

.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}

.status-present,
.present .track_schedule {
  background-color: #00ba341a !important;
  color: #00ba34;
  padding: 4px 12px;
  border-radius: 4px;
}

.status-absent,
.absent .track_schedule {
  background-color: #ff3b3b1a !important;
  color: #e92c2c;
  padding: 4px 12px;
  border-radius: 4px;
}

.status-leave,
.leave .track_schedule {
  background-color: #ff961b1a !important;
  color: #ff9f2d;
  padding: 4px 12px;
  border-radius: 4px;
}
.status-working,
.working .track_schedule,
.nottaken .track_schedule {
  background-color: #0085ff1a !important;
  color: #0085ff;
  padding: 4px 12px;
  border-radius: 4px;
}

.noclass .track_schedule {
  background-color: #ff961b1a !important;
  color: #ff6600;
  padding: 4px 12px;
  border-radius: 4px;
}
.holiday .track_schedule {
  background-color: #6a2dff17 !important;
  color: #6a2dff;
  padding: 4px 12px;
  border-radius: 4px;
}

.react-calendar__month-view__days__day--weekend {
  color: #969696;
}
.fc-event {
  border: 1px solid #ff6600;
  background-color: #ff6600;
}
abbr[title] {
  text-decoration: none;
}
.track_text {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0;
}
.tag_data {
  color: #0085ff;
  font-size: 14px;
  padding: 0px 5px;
  border-radius: 4px;
  margin-bottom: 0;
  font-weight: 500;
}
.tag_content {
  background-color: #0085ff3b !important;
  color: #0085ff;
  padding: 4px 20px;
  border-radius: 4px;
  width: 209px;
}
.attendance_view {
  padding: 30px;
}
.model_popup {
  display: flex;
}
.qr_container {
  background-color: #f6f6f6;
  width: 50%;
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.attendancemodal .modal-header {
  border-bottom: none;
  justify-content: center;
}
.modal-header {
  border-bottom: none;
  /* justify-content: center; */
}
.modal-body {
  /* padding: 0; */
  background-color: white;
}
.modal-footer-attend {
  position: absolute;
  top: -76px;
  width: 100%;
  justify-content: center;
  border-top: unset !important;
}
.close_btn {
  padding: 20px;
}
.attendancemodal .modal-content .modal-header {
  background-color: transparent;
  border: unset;
}
.modal-header .btn-close {
  /* display: none; */
  margin: unset;
  border-radius: 50px;
  background-color: #e2604a;
  font-size: 40px;
  padding: 4px;
  background-image: url("../../assets/images/close_btn.png");
}
.sub_text {
  font-size: 15px;
  font-weight: 400;
  color: #383838;
  margin-bottom: 25px;
}
.highlight_head {
  font-size: 32px;
  font-weight: bold;
}
.step_text {
  margin-top: 25px;
}
.list_head {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px;
}
.list_text {
  font-size: 13px;
  margin-bottom: 10px;
}
.schedule_container {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
.today_text {
  right: 15px;
  background-color: #0d4f70;
  top: 12px;
  color: #fff;
  position: absolute;
  padding: 3px 10px;
  border-radius: 24px;
  font-size: 14px;
}
.react-calendar__navigation__prev2-button,
.react-calendar__navigation__next2-button {
  display: none !important;
}
.react-calendar__navigation__label {
  border: unset !important;
  flex-grow: unset !important;
}
.react-calendar__navigation__prev-button,
.react-calendar__navigation__next-button {
  font-size: 31px;
  font-weight: bold;
  color: #585757;
}
.react-calendar__navigation__arrow {
  border: unset !important;
  background-color: unset;
}
/*#f6f6f6  */
/*  */

.chat_bot {
  position: fixed;
  right: 15px;
  bottom: 32px;
  z-index: 3;
  width: 160px;
}
.bot_button {
  background: #2c2c2d;
  cursor: pointer;
  border: transparent;
  color: #fff;
  height: 50px;
  border-radius: 10px;
  opacity: 1;
  filter: opacity(1);
  transition: all 0.3s ease-in;
  -webkit-transition: all 0.3s ease-in;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
}
.botbtn_text {
  color: white;
  font-weight: 700;
  padding-top: 11px;
  padding-left: 35px;
}
.chat_imgcontainer {
  position: absolute;
  top: -5px;
  left: -10px;
}
.chatbot_img {
  border-radius: 100%;
  width: 62px;
  height: 62px;
  border: 5px solid #2c2c2d;
  object-fit: cover;
  object-position: center;
  box-shadow: 1px 2px 7px rgba(0, 0, 0, 0.34);
}

/*  */

.marked_label {
  margin-top: 0;
  margin-bottom: 1rem;
  display: inline-flex;
  position: relative;
  gap: 42px;
  justify-content: flex-end;
  z-index: 0;
}
/* .calendar_head ul li ::before {
  content: "&#x2022;";
} */

.current-date {
  color: red;
}
.heading_section {
  width: 80%;
  margin: auto;
  text-align: start;
}
@media (max-width: 992px) {
  .chat_bot {
    display: none;
  }
}
