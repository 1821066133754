/*-------------font linking-------------*/
@font-face {
  font-family: "Poppins";
  src: url(../fonts/Poppins-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Poppins-Light";
  src: url(../fonts/Poppins-Light.ttf) format("truetype");
}
@font-face {
  font-family: "ReemKufiFun";
  src: url(../fonts/ReemKufiFun-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Montserrat";
  src: url(../fonts/Montserrat-Medium.ttf) format("truetype");
}
@font-face {
  font-family: "Eras-Bold";
  src: url(../fonts/ErasBoldITC.ttf) format("truetype");
}
@font-face {
  font-family: "Roboto";
  src: url(../fonts/Roboto-Regular.ttf) format("truetype");
}

/*-------------general styling-------------*/
:root {
  --primary: #de4b31;
  --secondary: #3f75ab;
  --primaryblack: #080a09;
}
html {
  scroll-behavior: smooth;
}
h1,
h2,
h3,
h4 {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}
.banner_head {
  font-family: "Eras-Bold";
}
p,
h5,
h6,
a,
ul,
button,
th,
span,
input[type="text"],
.custom_style,
.fil_btn,
.fb_btn,
.google_btn {
  /* font-family: "Montserrat" !important; */
  font-family: "Poppins";
  font-weight: 400 !important;
}
body {
  margin: 0;
  box-sizing: border-box;
  font-family: "Poppins";
  color: #080a09;
  font-size: 14px;
}
input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
}

/*-------------Navbar styling-------------*/
.main_nav {
  height: 115px;
}
.trans_header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
}
header {
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 10s;
  -ms-transition: all 10s;
  -o-transition: all 10s;
  transition: all 0.5s ease;
}
.mymenu {
  animation: smoothScrollup 1.5s;
}
@keyframes smoothScrollup {
  0% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}
.sticky {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  z-index: 9999;
  animation: smoothScroll 1s;
}
@keyframes smoothScroll {
  0% {
    transform: translateY(-40px);
  }
  100% {
    transform: translateY(0px);
  }
}
#nav-values .nav-link {
  color: #fff;
  font-weight: 600;
  font-size: 18px;
  letter-spacing: 1px;
}
#nav-values.nav_bg .nav-link,
.sticky #nav-values .nav-link {
  color: var(--secondary);
  font-size: 15px;
  white-space: nowrap;
}
#nav-values .nav-link:hover,
#nav-values .nav-link.active,
.footer_widget a:hover,
.footer_copyright a:hover,
.blog_main:hover .blog_des,
.blog_main:hover .blog_head,
.footer_widget .widget_2 a:hover i,
.pro_price,
.reset_cls i {
  color: var(--primary);
}
.social_icon:hover {
  color: #000 !important;
}
.trans_header .navbar-dark .navbar-toggler {
  border-color: #fff;
}
header .navbar-toggler {
  padding: 5px;
}
header .navbar-dark .navbar-toggler-icon {
  background: none;
}
.trans_header
  .navbar-dark
  button[aria-expanded="true"]
  .navbar-toggler-icon
  i:before {
  content: "\f00d";
  color: #fff;
}
.trans_header .navbar-dark .navbar-toggler-icon i {
  font-size: 22px;
  line-height: 30px;
}
.main_nav .login_btn {
  color: #fff;
  border: 1px solid #fff;
  border-radius: 4px;
  padding: 4px 17px;
  font-size: 18px;
  margin-right: 10px;
  text-decoration: none;
  letter-spacing: 2px;
}
.main_nav .login_btn:hover,
.main_nav .login_btn:hover i,
.sticky .main_nav .login_btn:hover,
.sticky .main_nav .login_btn:hover i {
  color: var(--primary);
  border-color: var(--primary);
}
.main_nav .login_btn i {
  border-radius: 50%;
  border: 1px solid #fff;
  width: 28px;
  height: 28px;
  text-align: center;
  line-height: 26px;
  letter-spacing: 0;
}
.social_icon {
  color: #fff;
  font-size: 22px;
  margin: 0 7px;
}
.sticky.trans_header .social_icon {
  color: #bcbabb;
  font-size: 16px;
}
/*.social_icon:hover, .sticky.trans_header .social_icon:hover
{
color: var(--primary);
}*/
.sticky .main_nav {
  height: 80px;
}
.sticky .nav-brand img {
  max-width: 80%;
}
.sti_home_search {
  display: none;
}
.sticky .sti_home_search {
  display: block;
}
.trans_header.sticky .logo_div,
.header_shad .logo_div {
  width: 150px;
}
.social_icon.lin_color:hover,
.sticky.trans_header .social_icon.lin_color:hover {
  color: #0a66c2;
}
.social_icon.tw_color:hover,
.sticky.trans_header .social_icon.tw_color:hover {
  color: #1da1f2;
}
.social_icon.fb_color:hover,
.sticky.trans_header .social_icon.fb_color:hover {
  color: #3b5998;
}

/*-------------Footer widget styling-------------*/
.footer_widget {
  padding: 30px 0;
  border-top: 1px solid #f2f2f2;
}
.footer_widget .widget_title {
  text-transform: uppercase;
  font-size: 16px;
  letter-spacing: 2px;
  margin-bottom: 25px;
}
.footer_widget a,
.footer_copyright a {
  color: #080a09;
  text-decoration: none;
}
.footer_widget .widget_1 p,
.footer_widget .widget_2 a {
  font-size: 14px;
}
.footer_widget .widget_2 i {
  margin-right: 5px;
}

/*-------------Copyright Footer styling-------------*/
.footer_copyright {
  background-color: #eceded;
  padding: 20px 0;
}
.footer_copyright h6 {
  letter-spacing: 2px;
}

/*-------------Home Page styling-------------*/
/* .home_banner {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 600px !important;
  background-color: #584f4f;
  background-blend-mode: overlay;
} */
.banner_slider .slick-dots {
  justify-content: center;
  margin: 0;
  padding: 1rem 0;
  list-style-type: none;
  position: absolute;
  bottom: 0;
  left: 48%;
  z-index: 9;
}
.banner_slider .slick-dots li {
  margin: 0 0.25rem;
  display: inline-block;
}
.banner_slider .slick-dots button {
  display: block;
  width: 1rem;
  height: 1rem;
  padding: 0;
  border: none;
  border-radius: 100%;
  background-color: #fff;
  text-indent: -9999px;
}
.banner_slider .slick-dots li.slick-active button {
  background-color: var(--primary);
}
.banner_content {
  position: absolute;
  top: 30%;
  width: 100%;
  justify-content: center;
  padding-left: 30px;
}
.homebanner_sec,
.productfilter.sel_arrow,
.singlediv {
  position: relative;
}
.banner_head {
  font-size: 47px;
  font-weight: 700;
}
.banner_subhead {
  font-size: 18px;
  letter-spacing: 3px;
}
.home_search.header_search {
  border: none;
  background-color: #fff;
  border-radius: 8px;
  padding: 5px 10px;
}
.home_search.header_search i {
  font-size: 25px;
}
.sec_head {
  font-size: 30px;
  border-bottom: 1px solid #cacaca;
  display: inline-block;
  padding-bottom: 10px;
}
.slide_box .cate_img {
  height: 400px;
  border-radius: 0 0 50px 50px;
}
.slide_box {
  box-shadow: 0 0 10px 4px #f2f2f2;
  -webkit-box-shadow: 0 0 10px 4px #f2f2f2;
  -moz-box-shadow: 0 0 10px 4px #f2f2f2;
  margin: 15px;
  color: #5f5f5f;
}
.slide_box a,
.sportz_slider a {
  color: #5f5f5f;
  text-decoration: none;
}
.slide_box:hover h4,
.sportz_slibox:hover h5 {
  color: var(--primary);
}
.slick-dots {
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 1rem 0;
  list-style-type: none;
}
.slick-dots li {
  margin: 0 0.25rem;
  display: inline-block;
}
.academies_slider .slick-dots button,
.person_slider .slick-dots button,
.pack_slider .slick-dots button,
.current_pack .slick-dots button,
.update_pack .slick-dots button,
.pro_slider .slick-dots button {
  display: block;
  width: 15px;
  height: 15px;
  padding: 0;
  border: 1px solid #c4c4c4;
  border-radius: 100%;
  background-color: transparent;
  text-indent: -9999px;
}
.slide_box h4 {
  font-size: 18px;
}
.academies_slider .slick-dots li.slick-active button,
.person_slider .slick-dots li.slick-active button,
.pack_slider .slick-dots li.slick-active button,
.current_pack .slick-dots li.slick-active button,
.update_pack .slick-dots li.slick-active button,
.pro_slider .slick-dots li.slick-active button {
  background-color: var(--primary);
  border-color: var(--primary);
}
.sportz_slider .cate_img {
  width: 170px;
  height: 170px;
  border-radius: 50%;
  margin: auto;
}
.sportz_slider .slick-arrow,
.gym_slider .slick-arrow {
  position: absolute;
  top: 50%;
  margin-top: -15px;
  width: 40px;
  height: 40px;
  font-size: 20px;
  background-color: #f2f2f2;
  border-radius: 50%;
  text-align: center;
  line-height: 38px;
  cursor: pointer;
}
.sportz_slider .prev-arrow {
  left: -45px;
}
.sportz_slider .next-arrow {
  right: -45px;
}
.sportz_slibox h5 {
  font-size: 17px;
}
.homesort_row {
  border-bottom: 1px solid #d7d2d2;
}
.homesortform .sort_label {
  font-size: 17px;
  color: #000;
  border: 1px solid var(--primary);
  border-radius: 6px;
  padding: 4px 15px;
  height: 40px;
  line-height: 30px;
}
.homesortform .sort_label i {
  -webkit-text-stroke: 1px;
  -webkit-text-stroke-color: var(--primary);
  -webkit-text-fill-color: #fff;
}
.homesortform.sortform select {
  color: #fff;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: var(--primary);
  border: 1px solid var(--primary);
  border-radius: 6px;
  padding: 4px 15px;
  font-size: 17px;
  text-align: center;
  width: 140px;
  height: 40px;
}
.homesortform.sortform select option {
  background-color: #fff;
  color: #000;
}
.gym_img {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  height: 200px;
  border-radius: 15px 15px 0px 0;
}
.gym_slider .slide_box {
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
}
.gym_slider .slide_box .gym_box {
  border-radius: 15px;
  background-color: #fff;
}
.gym_slider .slick-list {
  padding-left: 0 !important;
  /* margin: -15px; */
}
.gym_slider .slick-slide {
  /* margin: 15px; */
  position: relative;
  top: -29px;
  -webkit-transition: top 2.5s ease;
  -moz-transition: top 25s;
  -ms-transition: top 25s;
  -o-transition: top 25s;
  transition: top 2.5s ease;
}
.gym_slider .slick-slide.slick-current {
  top: 0;
  transition: top 2.5s ease;
}
.gym_slider .slick-slide .gym_box {
  -webkit-transition: transform 2.5s ease;
  -moz-transition: transform 25s;
  -ms-transition: transform 25s;
  -o-transition: transform 25s;
  transition: transform 2.5s ease;
  transition-delay: 0.5s;
}
.gym_slider .slick-slide:not(.slick-current) .gym_box {
  transition-delay: 0s;
  -webkit-transform: scale(1, 0.85);
  -moz-transform: scale(1, 0.85);
  -ms-transform: scale(1, 0.85);
  -o-transform: scale(1, 0.85);
  transform: scale(1, 0.85);
}
.gym_full {
  color: #fff !important;
  position: relative;
  background-image: url("../images/gymbg2.jpg");
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
}
.curveshape {
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100px;
  position: relative;
  bottom: -2px;
}
.curveshape.curvetop {
  background-position: bottom;
  top: -2px;
  bottom: auto;
}
.gym_slider .prev-arrow {
  right: 175px;
}
.gym_slider .next-arrow {
  right: 125px;
}
.gym_slider .slick-arrow {
  top: auto;
  bottom: 0;
  background-color: #fff;
  color: #000;
  z-index: 9;
}
.gym_slider .slick-slide {
  height: inherit !important;
}
.gym_btn {
  color: #fff;
  border: 1px solid #fff;
}
.gym_btn:hover {
  color: var(--primary);
  background-color: #fff;
}
.product_img {
  height: 250px;
  width: 350px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #fff;
}
.product_box {
  margin: 10px 0;
  height: 470px;
  width: 350px;
  margin: auto;
  position: relative;
}
.pro_slider:after {
  content: "";
  width: 100%;
  height: 75%;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--primary);
  z-index: -1;
}
.viewall_Sec {
  background-color: var(--primary);
  margin-top: -3px;
}
.pro_name,
.pro_name:hover {
  color: var(--secondary);
}
.pro_des {
  color: #c0c5c8;
  height: 45px;
  overflow-y: hidden;
}
.pro_btn {
  border: 1px solid var(--primary);
  color: var(--primary);
}
.btn:focus {
  outline: none;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
}
.pro_btn:hover {
  background-color: var(--primary);
  color: #fff;
}
.product_icons i {
  color: var(--primary);
  margin-left: 5px;
  height: 40px;
  width: 40px;
  border: 1px solid var(--primary);
  text-align: center;
  line-height: 40px;
  border-radius: 50%;
}
.product_icons i:hover {
  background-color: var(--primary);
  color: #fff;
}
.product_div {
  position: absolute;
  right: -30px;
  left: 60px;
  bottom: 40px;
  background-color: #fff;
  border: 1px solid #f2f2f2;
  margin: 10px 0;
  height: 225px;
  overflow-y: hidden;
}
.view_btn {
  border: 1px solid #fff;
  color: #fff;
}
.view_btn:hover {
  background-color: #fff;
  color: var(--primary);
}
.pro_slider .slick-dots button {
  border-color: #fff;
}
.pro_slider .slick-dots li.slick-active button {
  background-color: #fff;
  border-color: #fff;
}
.hometab + .tab-content .gym_full {
  height: 750px;
  display: flex;
  align-items: center;
}
.hometab .nav-tabs,
.hometab .nav-tabs .nav-link:focus,
.hometab .nav-tabs .nav-link:hover,
.hometab .nav-tabs .nav-item.show .nav-link,
.hometab .nav-tabs .nav-link.active,
.hometab .nav-tabs .nav-link {
  border: none;
}
.hometab .nav-tabs .nav-link {
  font-size: 20px;
  color: #fff;
  background-color: var(--secondary);
  margin-right: 10px;
}
.hometab .nav-tabs .nav-link:focus,
.hometab .nav-tabs .nav-link:hover,
.hometab .nav-tabs .nav-item.show .nav-link,
.hometab .nav-tabs .nav-link.active {
  background-color: var(--primary);
}
.reset_cls {
  border: 1px solid var(--primary);
  background-color: #fff;
  color: #000;
  padding: 3px 10px;
  border-radius: 4px;
  /*height: 40px;*/
  line-height: 1.3;
}
.reset_cls input {
  border: none;
  background-color: #fff;
  padding: 0;
}
.reset_cls:hover,
.reset_cls:hover input {
  background-color: var(--primary);
  color: #fff;
}
.reset_cls:hover i {
  color: #fff;
}
.filterform .reset_cls {
  padding: 5px 18px;
  font-size: 16px;
  height: 38px;
  line-height: 26px;
  margin: 0;
}
.fil_btn {
  border: 1px solid var(--primary);
  background-color: var(--primary);
  color: #fff;
  padding: 5px 18px;
  border-radius: 4px;
  font-size: 16px;
  height: 38px;
}
.fil_btn:hover {
  color: var(--primary);
  background-color: #fff;
}
.fil_val {
  padding: 3px 20px 3px 6px;
  background: #fff;
  font-weight: 600;
  border: 1px solid #93a3ac33;
  font-size: 12px;
  margin-right: 15px;
  position: relative;
  margin-bottom: 10px;
  display: inline-block;
}
.fil_val:after {
  content: "×";
  font-size: 20px;
  font-weight: 500;
  position: absolute;
  top: -3px;
  right: 4px;
  cursor: pointer;
}
.mbl_bottom_sec {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  border-top: 1px solid #0000001a;
  padding: 10px 0;
  z-index: 999;
}
.mbl_bottom,
.mbl_bottom:hover {
  color: #93959f;
}
.mbl_bottom i {
  font-size: 20px;
  width: 25px;
  height: 25px;
  line-height: 25px;
  text-align: center;
}
.mbl_bottom h6 {
  font-size: 14px;
  margin-bottom: 0;
  white-space: nowrap;
}
.mbl_bottom img {
  max-width: 25px;
  max-height: 25px;
}

/*-------------store Page styling-------------*/
.all_product_img {
  width: 100%;
  height: 220px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.all_product_box {
  position: relative;
  height: 350px;
  margin-bottom: 30px;
}
.all_product_div {
  position: absolute;
  bottom: 20px;
  left: 15px;
  right: 15px;
  background-color: #fff;
  box-shadow: 2px 2px 6px 2px #00000030;
  -webkit-box-shadow: 2px 2px 6px 2px #00000030;
  -moz-box-shadow: 2px 2px 6px 2px #00000030;
  overflow: hidden;
  height: 130px;
}
.all_product_div .pro_name {
  font-size: 15px;
}
.all_product_box .pro_btn {
  font-size: 14px;
  padding: 5px;
}
.all_product_box .product_icons i {
  width: 30px;
  height: 30px;
  line-height: 30px;
}
.store_sel {
  border: none;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 100%;
  background-color: #fff;
}
.productfilter .reset_cls {
  height: 38px;
  line-height: 31px;
}
.fil_head {
  color: #000;
  text-decoration: none;
  padding: 5px 0;
  font-size: 18px;
  border-bottom: 1px solid #e2e2e2;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.fil_head:hover {
  color: var(--primary);
  text-decoration: none;
}
#accordion .down-arrow.rotate {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.store_ip {
  margin-right: 10px;
  width: 20px;
  height: 20px;
}
.store_sort .sel_arrow {
  position: relative;
  height: auto;
  width: auto;
  padding-right: 35px;
  background-repeat: no-repeat;
  background-position: calc(100% - 20px) 50%;
}
.store_price .price-range-slider {
  width: 100%;
  float: left;
  padding: 10px 0px;
}
.store_price .range-value {
  margin: 0;
}
.store_price .range-value input {
  width: 60%;
  background: none;
  color: #000;
  font-size: 14px;
  font-weight: initial;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  border: none;
}
.store_price .range-bar {
  border: none;
  background: #ebebeb;
  height: 6px;
  width: calc(100% - 20px);
  position: relative;
}
.store_price .ui-slider-range {
  background: var(--primary);
}
.store_price .ui-slider-handle {
  position: absolute;
  border: none;
  border-radius: 0px;
  background: #000;
  border: 3px solid #fff;
  height: 20px;
  width: 20px;
  top: -0.52em;
  cursor: pointer;
  z-index: 2;
}
.store_price .ui-slider-horizontal:before {
  z-index: -1;
  content: "";
  position: absolute;
  top: 0;
  width: 20px;
  height: 100%;
  background-color: #ebebeb;
  left: 100%;
}
.store_price .ui-slider-handle.ui-state-focus {
  outline: none;
}
.store_price .ui-slider .ui-slider-range {
  position: absolute;
  z-index: 1;
  font-size: 0.7em;
  display: block;
  border: 0;
  background-position: 0 0;
}
.store_price .ui-slider-horizontal .ui-slider-range {
  top: 0;
  height: 100%;
}

/*-------------storesingle Page styling-------------*/
.pro_single_head {
  color: var(--primary);
  font-size: 35px;
}
.pro_single_price {
  color: var(--primary);
  font-weight: 900;
}
.quan_div {
  border: 1px solid #e2e2e2;
  width: 135px;
}
.quan_ip {
  border: none !important;
  outline: none !important;
  text-align: center;
  color: #7c7c7c;
  padding: 0;
}
.quan_btns i {
  font-size: 13px;
  color: #7c7c7c;
  width: 20px;
  height: 20px;
  line-height: 21px;
  border-radius: 50%;
  background-color: transparent;
  text-align: center;
}
.quan_btns i:hover {
  background-color: #e2e2e2;
}
.sig_btn {
  width: 165px;
  color: #000;
  border: 1px solid #e2e2e2;
  padding: 10px;
  margin: 10px 0;
  display: block;
}
.sig_btn:hover {
  color: var(--primary);
  border-color: var(--primary);
}
.single_box {
  border: 1px solid #e2e2e2;
}
.single_border {
  border-right: 1px solid #e2e2e2;
  border-left: 1px solid #e2e2e2;
}
.pro_info h6 {
  white-space: nowrap;
}

/*-------------Category Page styling-------------*/
.banner_sec,
.cate_img {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 135px;
}
.main_heading {
  color: var(--secondary);
  font-weight: 900;
  font-size: 26px;
  letter-spacing: 1px;
  white-space: nowrap;
}
#filterdiv.open .filtersidebar,
#mblfilterdiv.open,
#mblfilterdiv.open .filterdiv_inner {
  width: 100%;
  right: 0;
}
.filtersidebar {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 10000;
  top: 0;
  bottom: 0;
  right: -80px;
  background-blend-mode: overlay;
  background-color: #00000080;
  overflow-y: scroll;
}
.filterdiv_inner,
.productfilterdiv {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 10001;
  top: 0;
  bottom: 0;
  right: -80px;
  background-color: white;
  overflow-x: hidden;
  transition: 0.5s;
  text-align: left;
  padding: 30px 30px 0;
  overflow-y: scroll;
}
#filterdiv.open .filterdiv_inner,
#storefilterdiv.open .productfilterdiv {
  width: 400px;
  right: 0;
}
.filter_footer {
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
  width: 400px;
  margin-bottom: 0;
  margin-left: -30px;
  padding: 20px 30px;
  box-shadow: 0 -2px 4px 0 #e9e9eb;
  -webkit-box-shadow: 0 -2px 4px 0 #e9e9eb;
  -moz-box-shadow: 0 -2px 4px 0 #e9e9eb;
}
#filterdiv .closebtn {
  position: relative;
  top: 5px;
  left: 0px;
  font-size: 30px;
  margin-right: 10px;
  text-decoration: none;
}
#filterbtn .sort_label,
#storemain .sort_label {
  font-size: 16px;
  background-color: #fff;
  border: none;
}
.sort_label:focus {
  outline: none;
}
#filterbtn .sort_label i,
#storemain .sort_label i {
  width: 30px;
  height: 30px;
  color: var(--primary);
  border: 1px solid var(--primary);
  border-radius: 50%;
  line-height: 30px;
  font-size: 14px;
}
.sortform select {
  border: none;
  margin: 0 5px;
  font-size: 14px;
  color: #747474;
  background-color: #fff;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.filterform input[type="checkbox"] {
  margin-right: 5px;
}
.filterform input[type="checkbox"],
.filterform label {
  cursor: pointer;
}
.fil_row {
  border-bottom: 1px solid #e9e9eb;
}
.filterform h6 {
  font-size: 16px;
  margin-bottom: 15px;
}
.sort_div h6 {
  position: relative;
  cursor: pointer;
  color: #686b78;
  margin-right: 25px;
}
.sort_div h6.active,
.sort_div h6:hover {
  color: #3d4152;
}
.sort_div h6:after {
  content: "";
  display: block;
  border-bottom: 1px solid transparent;
  position: absolute;
  bottom: -11px;
  left: 0;
  right: 0;
}
.sort_div h6.active:after {
  border-bottom-color: #282c3f;
}
.cate_link {
  text-decoration: none !important;
}
.cate_box {
  padding: 15px;
  /* box-shadow: 0 0 5px 0 #00000080;
  -webkit-box-shadow: 0 0 5px 0 #00000080;
  -moz-box-shadow: 0 0 5px 0 #00000080; */
  color: #8a8a8a;
  font-size: 13px;
  /*height: 315px;*/
  overflow: hidden;
  margin: 15px 0;
}
.cate_box:hover {
  border-color: #d3d5df;
  box-shadow: 0 4px 7px 0 rgb(218 220 230 / 60%);
}
.cate_img {
  height: 200px;
}
.cate_left {
  width: 100%;
}
.city_info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}
.cate_left h5 {
  font-size: 14px;
  color: var(--secondary);
  font-weight: 600;
}
.cate_left i {
  color: #e9ecef;
  font-size: 12px;
}
.cate_left .aca_str {
  color: #f6921e;
}
.cate_price {
  color: var(--primary);
  white-space: nowrap;
}
.star_rating {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}
.stars {
  background-color: rgb(3, 184, 3);
  padding: 5px 8px;
  color: white;
  font-size: 11px;
}
.start_rating {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.cate_pagination .page-link {
  border: none;

  font-size: 14px;
  padding: 6px 13px;
  border-radius: 4px;
  margin: 0 3px !important;
}
.cate_pagination .page-item.active,
.cate_pagination .page-link:hover,
.cate_pagination .page-link:active {
  background-color: #6a85fb !important;

  color: #fff;
}
.active > .page-link {
  background-color: #6a85fb !important;
}
.drop_content,
.header_search .drop_content i {
  font-size: 17px;
  color: var(--primary);
}
.drop_txt {
  color: #9c9c9c;
  font-size: 13px;
  margin-left: 31px;
}
.header_search.home_search .dropdown-menu {
  width: 400px;
  left: -28px !important;
  border-radius: 8px;
}
.header_search .dropdown-menu {
  width: 400px;
  left: -22px !important;
  border-radius: 8px;
  top: 44px;
}
/* .dropdown-toggle::after {
  position: relative;
  left: -43px;
} */
.header_search .show .dropdown-toggle::after {
  transform: rotate(180deg);
}
.header_search.wi_300 .input-mini {
  width: 120px;
}
#location_type .input-mini {
  border-right: 1px solid #cfcfcf !important;
}
.dropdown-toggle {
  position: relative;
}

/*-------------About us styling-------------*/

.testimonial_slider .slide-arrow {
  position: absolute;
  top: 50%;
  margin-top: -15px;
  -webkit-text-stroke: 1px;
  -webkit-text-stroke-color: #0a5d8d;
  -webkit-text-fill-color: var(--secondary);
  font-size: 60px;
  cursor: pointer;
}
.testimonial_slider .prev-arrow {
  left: -145px;
}
.testimonial_slider .next-arrow {
  right: -145px;
}

/* Sports Single styling*/
.sportsbanner {
  height: 300px !important;
}
.single_txt .font2 {
  color: #757575;
}
.singlevideo_slider .prev-arrow {
  left: 50px;
}
.singlevideo_slider .next-arrow {
  right: 50px;
}
.singlevideo_slider .slick-arrow {
  position: absolute;
  top: 50%;
  margin-top: -15px;
  font-size: 45px;
  cursor: pointer;
  color: #989898;
  z-index: 1;
}
.pack_slider .slick-arrow,
.person_slider .slick-arrow {
  font-size: 35px;
}
fieldset {
  clear: both;
}
.msfform fieldset:not(:first-of-type) {
  display: none;
}
.msfform input[type="radio"],
.msfform input[type="checkbox"] {
  position: absolute;
  opacity: 0;
}
.msfform label {
  width: 100%;
}
.msfform .form-card {
  min-height: 500px;
  overflow: hidden;
  padding: 10px;
}
.msfform .form-card.step3 {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
}
#progressbar {
  overflow: hidden;
  color: #aeaeb0;
  padding-inline-start: 0;
  height: 45px;
}
#progressbar .active {
  color: var(--secondary);
}
#progressbar .active strong {
  color: #000;
}
#progressbar li {
  list-style-type: none;
  font-size: 17px;
  width: 19%;
  float: left;
  position: relative;
  font-weight: 400;
}
#progressbar li:first-child {
  width: 15%;
}
#progressbar.gym_bar li:first-child {
  width: 15%;
}
#progressbar li.active:before {
  content: "";
  width: 0;
  height: 0;
  display: block;
  margin: 0 auto 10px auto;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid var(--primary);
  position: absolute;
  top: 29px;
  left: 12%;
}
#progressbar li:after {
  content: "";
  width: 100%;
  height: 2px;
  background: var(--primary);
  position: absolute;
  left: 0;
  top: 28px;
  z-index: -1;
}
.gym_trainer {
  font-size: 17px;
  margin-right: 15px;
}
.trainer_price {
  font-size: 15px;
  color: #aeaeb0;
}
.pack_slider .slick-track,
.person_slider .slick-track {
  overflow: hidden;
}
.pack_slider .slick-list,
.person_slider .slick-list {
  width: 100% !important;
}
.pack_slider .slick-slide,
.person_slider .slick-slide {
  display: block !important;
}
.pack_slider .slick-track,
.person_slider .slick-track {
  display: block !important;
}
.pack_box {
  box-shadow: 0 0 10px 0 #00000080;
  -webkit-box-shadow: 0 0 10px 0 #00000080;
  -moz-box-shadow: 0 0 10px 0 #00000080;
}
.pack_box_top {
  background-image: url("../images/packimg.png");
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  height: 182px;
  padding-top: 20px;
}
.aca_des {
  height: 100px;
  overflow: hidden;
}
.pack_content {
  padding: 0 15px;
}
.pack_box h5 {
  font-size: 13px;
  line-height: 1.4;
}
.pack_des {
  height: 125px;
  overflow: hidden;
}
.pack_box_top h6 {
  font-size: 13px;
  text-decoration: line-through;
  color: #9a989a;
}
.pack_box_bottom h5 i {
  font-size: 15px;
  width: 15%;
}
.action-button,
.action-button-previous {
  background: var(--primary);
  font-weight: bold;
  color: white;
  border: 1px solid var(--primary);
  border-radius: 30px;
  font-size: 20px;
  text-transform: uppercase;
  width: 150px;
  height: 40px;
  margin-top: 30px;
}
.pre_book_button {
  background: var(--primary);
  font-weight: bold;
  color: white;
  border: 1px solid var(--primary);
  border-radius: 30px;
  font-size: 20px;
  text-transform: uppercase;
  width: 150px;
  height: 40px;
}
input[type="submit"].action-button {
  width: 200px;
}
.action-button-previous {
  background-color: #bebebe;
  border-color: #bebebe;
}
.select_span {
  width: 40px;
  height: 40px;
  margin: auto;
  border-radius: 50%;
  color: #fff;
  font-size: 25px;
  border: 1px solid var(--primary);
  line-height: 40px;
}
.msfform input[type="radio"]:checked + .pack_box .select_span,
.msfform input[type="radio"]:checked + .person_box .select_span,
.msfform input[type="radio"]:checked + .slottime_box .select_span,
.msfform input[type="checkbox"]:checked + .person_box .select_span {
  background-color: var(--primary);
}

.prebookcolor {
  background-color: var(--primary);
}

.person_box img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 50%;
  margin: auto;
}
.person_box h6 {
  color: #aeaeb0;
}
.slottime_inner {
  box-shadow: 0 0 10px 0 #00000080;
  -webkit-box-shadow: 0 0 10px 0 #00000080;
  -moz-box-shadow: 0 0 10px 0 #00000080;
  border-radius: 30px;
  padding: 15px;
  /* white-space: nowrap; */
}
#Datepicker1 a {
  color: #000;
  text-decoration: none;
}
#Datepicker1 .ui-datepicker-next {
  float: right;
}
#Datepicker1 .ui-datepicker-title {
  text-align: center;
  padding: 20px 0;
}
#Datepicker1 .ui-datepicker-next,
#Datepicker1 .ui-datepicker-prev {
  cursor: pointer;
}
#Datepicker1 .react-datepicker {
  font-family: "ReemKufiFun";
}
#Datepicker1 .react-datepicker__day {
  color: gray;
  width: 40px;
  font-weight: 700;
}
#Datepicker1 td {
  border-bottom: 2px solid transparent;
}
#Datepicker1 .react-datepicker__day--selected {
  border-bottom: 2px solid var(--primary);
  background-color: #fff;
  border-radius: 0;
}
#Datepicker1 .react-datepicker__day-name {
  color: var(--primary);
  text-transform: uppercase;
  width: 40px;
  font-weight: 800;
}
#Datepicker1 .react-datepicker {
  border: none;
}
#Datepicker1 .react-datepicker__header {
  background-color: #fff;
  border: none;
}
#Datepicker1 .react-datepicker__navigation-icon:before {
  border-color: #000;
}
.nxt_btn {
  background-color: var(--primary);
  color: #fff;
  line-height: 0.9;
  font-size: 28px;
  padding: 0 10px 5px;
  margin-top: 10px;
  border-color: var(--primary);
}
.nxt_btn:hover {
  color: var(--primary);
  background-color: #fff;
}
.dis_date {
  background-color: var(--primary);
}
.dis_date h1 {
  font-size: 125px;
}
.alt_font {
  font-family: "ReemKufiFun";
}
.btn1 {
  position: relative;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  column-gap: 15px;
}
button.js-video-button {
  position: absolute;
  top: 46%;
  border: none;
  outline: none;
}
button.js-video-button-blog {
  position: absolute;
  top: 46%;
  border: none;
  outline: none;
}
.btn1 .play {
  position: absolute;
  height: 50px;
  width: 50px;
  background: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s;
}
.btn1 .play::before {
  content: "";
  position: absolute;
  border: 25px solid var(--primary);
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
  border-right: 0px solid transparent;
  transform: translateX(5px);
}
.vid__image {
  width: 450px;
  height: 220px;
  margin: auto;
  object-fit: cover;
}
.pack_btn.send {
  width: 180px;
  font-size: 16px;
}
.choose_slot_show {
  display: block !important;
}

/* sidebar styling*/
.right_sidebar {
  box-shadow: 0 0 25px 0 #38363680;
  -webkit-box-shadow: 0 0 25px 0 #38363680;
  -moz-box-shadow: 0 0 25px 0 #38363680;
  padding: 20px;
}
.siderbar_head {
  color: var(--secondary);
  font-size: 21px;
}
.sub_txt,
a.cmt_txt {
  color: var(--primary);
  font-size: 13px;
}
a.cmt_txt:hover {
  color: var(--primaryblack);
}
button.sub_txt {
  background-color: #fff !important;
  border: none;
  padding: 0;
  text-align: left;
}
a.sub_txt {
  color: #aeaeae;
  text-decoration: underline;
}
.sub_txt span {
  color: #aeaeae;
}
.rating_txt {
  font-size: 45px;
  font-weight: 100;
  font-family: "Poppins-Light";
}
.rating_txt span {
  font-size: 20px;
}
.ra_star i {
  color: #e9ecef;
  font-size: 23px;
}
i.sele_str,
.review_str i.aca_str,
.cart_item:hover .cart_title,
.cart_item i:hover {
  color: var(--primary);
}
.star_val {
  font-size: 13px;
}
.pb_bgcolor {
  background-color: var(--primary);
  border-radius: 30px;
}
.probar {
  height: 12px;
  border-radius: 30px;
}
.cart_div .cart_item:not(:last-of-type) {
  border-bottom: 1px solid #ebebeb;
}
.cart_img {
  width: 50px;
  height: 50px;
  object-fit: cover;
}
.social_share i {
  width: 35px;
  height: 35px;
  text-align: center;
  line-height: 35px;
  border-radius: 4px;
  color: #fff;
  font-size: 15px;
}

.insta_share i {
  width: 33px;
  height: 33px;
  text-align: center;
  line-height: 32px;

  color: #fff;
  font-size: 23px;
  background-color: #e33e3e;
  cursor: pointer;
}
.social_share i.fa-facebook {
  background-color: #3a509c;
}
.social_share i.fa-twitter {
  background-color: #00a8f4;
}
.social_share i.fa-linkedin {
  background-color: #0178ba;
}
.social_share i.fa-whatsapp {
  background-color: #00a718;
}
.social_share i:hover {
  background-color: var(--primary);
}
.cart_title {
  color: #225c7b;
  font-size: 14px;
}
.sidebar .closebtn {
  font-size: 36px;
  line-height: 0px;
  text-decoration: none;
}

.openbtn {
  font-size: 50px;
  background-color: var(--primary);
  color: white;
  padding: 10px 15px;
  border: none;
  line-height: 20px;
}
.openbtn:focus {
  outline: none;
}
.openbtn i {
  font-size: 13px;
  letter-spacing: -8px;
}
#mySidebar.open .filtersidebar,
#storefilterdiv.open .filtersidebar {
  width: 100%;
  right: 0;
}
#main {
  position: fixed;
  top: 165px;
  right: 0;
  background-color: var(--primary);
  z-index: 1;
  width: auto;
}
.cross {
  padding: 10px;
  color: var(--primary);
  cursor: pointer;
  font-size: 23px;
}
.cross i {
  margin-top: -5px;
  cursor: pointer;
}
.comment-box {
  padding: 5px;
}
.comment-area textarea,
.cmt_form input {
  border: 1px solid #bfbfbf;
}
.comment-area textarea {
  resize: none;
}
.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: var(--primary);
  outline: 0;
}
.send {
  color: #fff;
  background-color: var(--primary);
  border-color: var(--primary);
}
.send:hover {
  color: var(--primary);
  background-color: transparent;
  border-color: var(--primary);
}
.rating {
  display: inline-flex;
  margin-top: -10px;
  flex-direction: row-reverse;
}
.rating > input {
  display: none;
}
.rating > label {
  position: relative;
  width: 28px;
  font-size: 23px;
  color: var(--primary);
  cursor: pointer;
  margin: 0;
}
.rating > label::before {
  content: "\f005";
  position: absolute;
  opacity: 0;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 23px;
}
.rating > label:hover:before,
.rating > label:hover ~ label:before {
  opacity: 1 !important;
}
.rating > input:checked ~ label:before {
  opacity: 1;
}
.rating:hover > input:checked ~ label:before {
  opacity: 0.4;
}
.cmt_box {
  padding: 20px 20px 1px;
  box-shadow: 0 0 8px 0 #00000080;
  -webkit-box-shadow: 0 0 8px 0 #00000080;
  -moz-box-shadow: 0 0 8px 0 #00000080;
  margin-bottom: 20px;
}
.review_str i {
  color: #e9ecef;
  font-size: 15px;
}
.lock-scroll {
  overflow: hidden;
}

@media (max-width: 991px) {
  .sidebar .right_sidebar {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 10001;
    top: 0;
    bottom: 0;
    right: -80px;
    background-color: white;
    overflow-x: hidden;
    transition: 0.5s;
    text-align: left;
    padding: 30px 15px;
    overflow-y: scroll;
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
  .sidebar.open .right_sidebar {
    width: 300px;
    right: 0;
  }
}

/*-------------Comments Page styling-------------*/
.cmts_form select,
.store_sel {
  padding: 9px;
  border: none;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 40px;
  width: 200px;
  background-color: #fff;
}
.sel_arrow {
  border: 1px solid #dce5e5;
  padding: 10px;
  font-size: 15px;
  position: relative;
}
.sel_arrow i {
  position: absolute;
  right: 15px;
  top: 15px;
}
.cmts_form label {
  font-size: 15px;
}
.cmt_profile {
  min-width: 80px;
  min-height: 80px;
  max-width: 80px;
  max-height: 80px;
  margin: auto;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
}
.cmt_box {
  padding: 20px;
  border: 1px solid #dce5e5;
  box-shadow: 0 0 1px 0 #787878;
  -webkit-box-shadow: 0 0 1px 0 #787878;
  -moz-box-shadow: 0 0 1px 0 #787878;
  margin-bottom: 20px;
  color: #787878;
}
.cmt_usr_name {
  color: #4cbdeb;
  font-weight: 700;
}
.cmt_time {
  font-size: 13px;
}
.comment-area textarea {
  border: 1px solid #bfbfbf;
  border-radius: 0;
  padding: 20px;
  font-size: 13px;
}
.send {
  color: #fff;
  background-color: var(--primary);
  border-color: var(--primary);
  border-radius: 0;
  font-size: 20px;
  width: 150px;
}
.send:hover,
.active_gym.send {
  color: var(--primary);
  background-color: transparent;
  border-color: var(--primary);
}
.trainer_sec {
  display: none;
}
.gym_btns,
.gym_btns:hover {
  font-size: 16px;
  width: 85px;
  height: 35px;
  color: var(--primary);
  background-color: #fff;
  border-color: var(--primary);
  border-radius: 0;
}
.active_gym.gym_btns {
  color: #fff;
  background-color: var(--primary);
}
.font_call.rating_heading {
  font-size: 20px;
  font-weight: 400;
  margin-right: 10px;
}
.rating_str:before {
  content: "\f005";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 23px;
  color: #828282;
}

/* After Login Package styles */
#progressbar.login_progressbar li {
  width: 33.33333%;
  font-size: 14px;
}
#progressbar.login_progressbar.gym_bar_login li {
  width: 22%;
  font-size: 14px;
}
#progressbar.login_progressbar.gym_bar_login li:nth-child(3) {
  width: 34%;
}

/*-------------blog Page styling-------------*/
.blog_full,
.blog_col {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  height: 400px;
  padding-top: 205px;
}
.blog_col {
  padding: 0;
  height: 230px;
}
.blog_full .blog_content {
  width: 650px;
  margin: auto;
}
.blog_main {
  margin-bottom: 26px;
}
.blog_des {
  line-height: 1.7;
  color: #000;
}
.sport_label {
  position: absolute;
  bottom: 26px;
  left: 0px;
  padding: 8px 6px;
}
.bg_pink {
  background-color: #d90444;
}
.bg_green {
  background-color: #57b600;
}
.bg_blue {
  background-color: #1954e6;
}
.bg_violet {
  background-color: #8300b2;
}
.blog_date,
.blog_by {
  color: #bababa;
  font-size: 12px;
  line-height: 1.5;
}
.blog_date:after {
  content: "|";
  padding: 0 8px;
}
.blog_by {
  color: var(--secondary);
  font-weight: 700;
  border-bottom: 1px solid;
}
.right_label {
  display: flex;
  align-items: baseline;
}
.secondary-clr {
  color: var(--secondary);
}

/*-------------blog single Page styling-------------*/
/* .single_content {
  width: 75%;
  margin: auto;
} */
.blog_heading {
  color: var(--primary);
  font-size: 30px;
}
.breadcrumb_sec .breadcrumb {
  background-color: transparent;
}
.breadcrumb_sec .breadcrumb-item a,
.breadcrumb_sec .breadcrumb-item {
  color: #fff;
  text-decoration: none;
  font-size: 17px;
}
.breadcrumb_sec .breadcrumb-item + .breadcrumb-item::before {
  content: ">";
  color: #fff;
}
.breadcrumb_sec .breadcrumb-item.active,
.breadcrumb_sec .breadcrumb-item a:hover {
  color: var(--primary);
}

/*-------------Login Page styling-------------*/
.log_label {
  width: 100%;
  color: black;
  margin-top: 30px;
  margin-bottom: 0;
  font-weight: bold;
}
.log_ip {
  border: none;
  border-bottom: 1px solid #b0d4df;
  width: 100%;
  padding-bottom: 12px;
}
.log_ip::placeholder {
  color: #000;
  font-weight: 900;
}
.log_btn {
  background-color: #485486;
  width: 170px;
  border-radius: 30px;
  height: 50px;
  color: #fff;
  font-size: 13px;
  box-shadow: 20px 13px 14px #cecfd4;
  -webkit-box-shadow: 20px 13px 14px #cecfd4;
  -moz-box-shadow: 20px 13px 14px #cecfd4;
}
.log_btn:hover {
  color: #fff;
  background-color: var(--primary);
}
.float_right {
  float: right;
  font-size: 14px;
}
.price_details {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Responsive */

@media (orientation: portrait) {
  .filter_footer {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
}
@media (min-width: 1440px) {
  .curveshape {
    height: 165px;
  }
}
@media (max-width: 1150px) {
  .sportz_slider .cate_img {
    width: 150px;
    height: 150px;
  }
  .sportz_slider .next-arrow {
    right: 0;
  }
  .sportz_slider .prev-arrow {
    left: 0;
  }
}
@media (min-width: 1200px) {
  .sticky.trans_header .navbar-nav {
    width: 450px;
  }
}
@media (max-width: 1199px) and (min-width: 992px) {
  .nav-brand img {
    width: 130px;
  }
  .sticky.trans_header .social_icon,
  #progressbar.gym_bar li {
    font-size: 15px;
  }
  #nav-values.nav_bg .nav-link,
  .sticky #nav-values .nav-link {
    font-size: 13px;
  }
  .sticky.trans_header .navbar-nav {
    width: 375px;
  }
  .social_icon {
    margin: 0 3px;
  }
  .header_search.home_search .dropdown-toggle::after {
    left: -30px;
  }
  .header_search.wi_300 {
    width: 240px;
  }
  .header_search.wi_300 .input-mini {
    width: 85px;
  }
  .dropdown-toggle::after {
    left: -10px;
  }
  .homesortform.sortform select {
    width: auto;
  }
  .pack_des {
    height: 165px;
  }
  .cmts_form select {
    width: 125px;
  }
  #progressbar.login_progressbar li,
  .all_product_box .pro_btn {
    font-size: 12px;
  }
  .all_product_div {
    padding: 10px !important;
    bottom: 40px;
  }
  .all_product_box .product_icons i {
    width: 25px;
    height: 25px;
    line-height: 25px;
  }
  .pro_info h6 {
    font-size: 13px;
  }
  .login_form .font_call {
    font-size: 26px;
  }
  .sticky .nav-brand img {
    max-width: 100%;
  }
  .cate_left h5 {
    font-size: 12px;
    white-space: nowrap;
  }
  #progressbar.login_progressbar.gym_bar_login li {
    width: 20%;
    font-size: 13px;
  }
  #progressbar.login_progressbar.gym_bar_login li:nth-child(3) {
    width: 40%;
  }
}
@media (max-width: 991px) and (min-width: 768px) {
  .homesortform.sortform select {
    width: auto;
    font-size: 15px;
    height: 35px;
    padding: 5px;
  }
  .homesortform .sort_label {
    height: 35px;
    line-height: 21px;
  }
  .header_search.home_search .dropdown-toggle::after {
    left: -30px;
  }
  .sort_div h6 {
    font-size: 13px;
    margin-right: 20px;
  }
  .pack_des {
    height: 145px;
  }
  .cmts_form select {
    width: 150px;
  }
  #progressbar.login_progressbar li {
    font-size: 13px;
  }
  .gym_slider .next-arrow {
    right: 0px;
  }
  .gym_slider .prev-arrow {
    right: 50px;
  }
  .product_box {
    width: 300px;
  }
  .product_img {
    width: 300px;
    height: 230px;
  }
  .single_border {
    border-top: 1px solid #e2e2e2;
    border-left: 0px;
  }
  .single_border2 {
    border-top: 1px solid #e2e2e2;
  }
  #progressbar.login_progressbar.gym_bar_login li {
    width: 20%;
  }
  #progressbar.login_progressbar.gym_bar_login li:nth-child(3) {
    width: 40%;
  }
}
@media (max-width: 991px) {
  .main_nav {
    height: 100px;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
    -webkit-box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
    -moz-box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
    display: none;
  }
  .sticky,
  .header_shad {
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
    -webkit-box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
    -moz-box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
  }
  .main_nav .navbar-nav {
    padding: 0 !important;
  }
  .sticky.trans_header .main_nav {
    height: 80px;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
    -webkit-box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
    -moz-box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
  }
  .nav-brand img {
    width: 100px;
  }
  .nav_rel {
    position: relative;
  }
  .nav_bg,
  #nav-values {
    position: absolute;
    top: 48px;
    left: 0px;
    right: 0px;
    width: 300px;
    padding: 40px !important;
  }
  .home #nav-values {
    top: 6px;
    padding-top: 70px !important;
  }
  .home .trans_header .navbar-dark button {
    z-index: 99;
  }
  .trans_header .navbar-dark button[aria-expanded="true"] {
    border: none;
  }
  .sticky .navbar-toggler-icon i {
    color: #000;
  }
  .sticky .nav-brand img {
    max-width: 100%;
  }
  .sticky.trans_header .navbar-dark .navbar-toggler {
    border-color: #000;
  }
  .trans_header.sticky #nav-values .nav-link {
    color: #fff;
    font-weight: 600;
    font-size: 18px;
    letter-spacing: 2px;
  }
  .trans_header.sticky #nav-values .nav-link.active,
  .trans_header.sticky #nav-values .nav-link:hover {
    color: var(--primary);
  }
  .sticky.trans_header .main_nav .login_btn {
    color: #fff;
    border-color: #fff;
    padding: 4px 17px;
    font-size: 18px;
  }
  .sticky.trans_header .main_nav .login_btn i {
    border-color: #fff;
    width: 28px;
    height: 28px;
    line-height: 26px;
  }
  .sticky.trans_header .social_icon {
    font-size: 22px;
    color: #fff;
  }
  #nav-values .nav-item {
    padding: 0 !important;
  }
  .social_icon {
    margin: 10px 14px 0 0;
  }
  .sear_btn {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #f2f2f2;
  }
  .web_login,
  .sticky .sti_home_search,
  .trans_header .mob_header_search {
    opacity: 0;
    visibility: hidden;
    transition: all 1.5s ease;
  }
  .trans_header.sticky .mob_header_search {
    opacity: 1;
    visibility: visible;
  }
  .mbl_nav {
    background-color: #1a1818e0;
    padding: 20px;
  }
  .home_banner {
    height: 550px !important;
  }
  .pack_box {
    margin-bottom: 15px;
  }
  .trans_header .main_nav {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
  .main_heading {
    font-size: 22px;
  }
  #commentbox {
    z-index: 9998;
  }
  #commentbox .modal-dialog {
    max-width: 80%;
    margin: 80px auto 80px auto;
  }
  .gym_head {
    font-size: 29px;
  }
  .hometab .nav-tabs .nav-link {
    font-size: 17px;
  }
  .navbar-nav.contact_link a {
    padding-right: 0px !important;
    padding-left: 0 !important;
  }
  .trans_header.sticky .logo_div,
  .header_shad .logo_div {
    text-align: right;
  }
  footer {
    margin-bottom: 87px;
  }
  .cate_left h5 {
    font-size: 13px;
  }
}
@media (min-width: 768px) {
  .msfform .form-card.step2 {
    padding: 120px 0;
  }
}
@media (max-width: 767px) {
  .homesortform.sortform select {
    width: auto;
    height: 35px;
    font-size: 13px;
    margin-bottom: 10px;
    padding: 4px 3px;
  }
  .homesortform .sort_label,
  .homesortform .reset_cls {
    height: 35px;
    padding: 0 10px;
    font-size: 14px;
    line-height: 32px;
  }
  .pack_des {
    height: 125px;
  }
  .footer_widget .widget_title,
  .person_box {
    margin-bottom: 15px;
  }
  .cate_box {
    width: 330px;
    margin: 15px auto;
  }
  .testimonial_slider .next-arrow {
    right: -40px;
  }
  .testimonial_slider .prev-arrow {
    left: -40px;
  }
  .header_search.home_search .dropdown-toggle::after {
    left: -27px;
  }
  #location_type .input-mini,
  .blog_full .blog_content,
  .single_content {
    width: 100%;
  }
  .single_txt .font_call,
  .blog_head {
    font-size: 25px;
  }
  .pack_slider label,
  .current_pack label,
  .update_pack label,
  .current_package label {
    width: 225px;
    margin: auto;
  }
  .pack_slider .slick-slide,
  .person_slider .slick-slide,
  .current_pack .slick-slide,
  .update_pack .slick-slide {
    text-align: center;
  }
  .person_slider label {
    width: 160px;
    margin: auto;
  }
  .person_slider label {
    width: 220px;
    margin: auto;
  }
  .person_box img {
    width: 220px;
    height: 220px;
  }
  .slottime_inner {
    font-size: 10px;
    padding: 10px 15px;
  }
  .slottime_inner h6 {
    font-size: 14px;
  }
  .sortform span:after {
    font-size: 10px;
    margin-right: 0;
  }
  .msfform .form-card {
    min-height: 460px;
  }
  #progressbar {
    margin-bottom: 0;
  }
  .sort_div h6 {
    font-size: 15px;
  }
  .sort_div h6:after {
    bottom: -8px;
  }
  .fil_val {
    margin-right: 5px;
  }
  #progressbar.login_progressbar li {
    width: 26%;
    white-space: nowrap;
    font-size: 13px;
  }
  #progressbar.login_progressbar li:last-child {
    width: 48%;
  }
  .cmt_profile {
    max-height: 120px;
    max-width: 120px;
    min-width: 120px;
    min-height: 120px;
  }
  .gym_slider .next-arrow {
    right: 25px;
  }
  .gym_slider .prev-arrow {
    right: 78px;
  }
  .all_product_div {
    bottom: 30px;
  }
  .single_border {
    border-top: 0;
    border-left: 0px;
    border-right: 0px;
  }
  .single_border2 {
    border-top: 1px solid #e2e2e2;
  }
  .blog_des {
    line-height: 1.5;
  }
  .mbl_bottom h6 {
    font-size: 11px;
  }
  .mbl_bottom i {
    font-size: 15px;
  }
  footer {
    margin-bottom: 59px;
  }
  #progressbar.gym_bar li {
    font-size: 13px;
  }
  #progressbar.login_progressbar.gym_bar_login li {
    width: 20%;
    font-size: 11px;
  }
  #progressbar.login_progressbar.gym_bar_login li:nth-child(3) {
    width: 40%;
  }
}
@media (max-width: 575px) {
  .banner_slider .slick-dots {
    left: 43%;
  }
  .header_search.home_search .dropdown-menu {
    width: auto;
  }
  .single_txt .font_call,
  .blog_head {
    font-size: 20px;
  }
  .dis_date h1 {
    font-size: 40px;
  }
  .dis_date {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  #filterdiv.open .filterdiv_inner,
  .filter_footer,
  #storefilterdiv.open .productfilterdiv {
    width: 300px;
  }
  .filterform .reset_cls {
    padding: 5px 10px;
    font-size: 14px;
    height: 30px;
    line-height: 20px;
  }
  .fil_btn {
    padding: 0px 10px;
    font-size: 14px;
    height: 30px;
  }
  .all_product_box {
    width: 250px;
    margin: auto;
    margin-bottom: 20px;
  }
  .productfilter .reset_cls {
    height: 30px;
    line-height: 20px;
  }
  .blog_des {
    line-height: 1.2;
  }
  #progressbar.login_progressbar.gym_bar_login li {
    width: 22% !important;
    white-space: normal;
  }
  #progressbar.login_progressbar.gym_bar_login li:nth-child(3) {
    width: 34% !important;
  }
  #progressbar.login_progressbar.gym_bar_login {
    height: 65px;
  }
  #progressbar.login_progressbar.gym_bar_login li:after {
    top: 55px;
  }
  #progressbar.login_progressbar.gym_bar_login li.active:before {
    top: 56px;
  }
}
@media (max-width: 549px) {
  .gym_slider .prev-arrow {
    right: 35px;
  }
  .gym_slider .next-arrow {
    right: -7px;
  }
}
@media (max-width: 539px) {
  .homesortform.sortform select {
    height: 25px;
    font-size: 11px;
    padding: 2px;
    margin: 0 0px 10px;
    border-radius: 3px;
  }
  .homesortform .sort_label,
  .homesortform .reset_cls {
    height: 25px;
    padding: 0 4px;
    line-height: 22px;
    font-size: 12px !important;
    border-radius: 3px;
  }
  .homesortform .reset_cls input {
    display: none;
  }
  #filterbtn .sort_label,
  #storemain .sort_label {
    font-size: 11px;
  }
  #filterbtn .sort_label i,
  #storemain .sort_label i {
    width: 15px;
    height: 15px;
    line-height: 15px;
    font-size: 8px;
  }
  .sort_div h6 {
    font-size: 11px;
    margin-right: 11px;
  }
  .sort_div h6:after {
    bottom: -4px;
  }
}
@media (max-width: 530px) {
  .hometab .nav-tabs .nav-link {
    font-size: 14px;
    padding: 5px 10px;
    margin-right: 7px;
  }
}
@media (max-width: 500px) {
  #progressbar li {
    font-size: 12px;
  }
  #progressbar li:after {
    top: 20px;
  }
  #progressbar li.active:before {
    top: 21px;
  }
  #progressbar.login_progressbar li {
    font-size: 10px;
  }
  .action-button,
  .action-button-previous {
    width: 120px;
    font-size: 16px;
  }
  input[type="submit"].action-button {
    width: 145px;
  }
  #commentbox .modal-content {
    padding: 20px !important;
  }
  .reviewtab .nav-link {
    font-size: 13px;
    padding: 5px;
  }
  .comment-box .send {
    font-size: 13px;
  }
  .pack_btn.send {
    width: 150px;
    font-size: 14px;
  }
  .product_box {
    width: 270px;
    height: 380px;
  }
  .product_img {
    width: 270px;
    height: 200px;
  }
  .product_div {
    bottom: 0px;
    right: -24px;
    left: 35px;
  }
  .sportz_slibox h5 {
    font-size: 14px;
  }
  #progressbar.gym_bar li {
    width: 11%;
  }
  #progressbar.gym_bar li:first-child,
  #progressbar li:nth-child(2) {
    width: 33.5%;
  }
  #progressbar.gym_bar li {
    font-size: 11px;
  }
}
@media (max-width: 429px) {
  .gym_box h4 {
    font-size: 15px;
  }
}
@media (max-width: 400px) {
  .mbl_bottom h6 {
    font-size: 10px;
  }
  footer {
    margin-bottom: 57px;
  }
}
@media (max-width: 374px) {
  .cate_box {
    width: auto;
  }
  .cate_pagination .page-link {
    margin: 0 !important;
    font-size: 13px;
    padding: 5px 12px;
  }
  .test_div h5 {
    font-size: 17px;
  }
}

.nav_bg {
  background-color: rgb(255, 255, 255);
  z-index: 1;
  padding: 10px;
}

/*contact us css*/
.contact_banner {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.mob_search {
  display: none;
}

@media (max-width: 991px) {
  .mob_search {
    display: block;
    width: 100%;
  }
  .header_search_mob {
    display: none;
  }
  .mobi_search {
    width: 50%;
    float: left;
    text-align: right;
  }
  .mobi_location {
    width: 50%;
    float: left;
  }
  .mobi_icon {
    width: 90%;
  }
  .mobi_icon:focus-visible {
    outline: none;
  }
}

/*modal*/
.header_search .modal.fade .modal-dialog {
  margin-top: 90px;
}

.main_nav .login-btn1,
.sticky .main_nav .login_btn {
  color: #bcbabb;
  border: 1px solid #bcbabb;
  padding: 4px 5px;
  font-size: 13px;
  white-space: nowrap;
}
.main_nav .login-btn1 i,
.sticky .main_nav .login_btn i {
  width: 20px;
  height: 20px;
  line-height: 18px;
  border: 1px solid #bcbabb;
}
.header_search input,
.header_search select,
.header_search button {
  border: none;
  overflow: hidden;
}
.header_search {
  border: 1px solid #c3bfbf;
  padding: 0 5px;
}
.wi_300 {
  width: 300px;
}
.mob_header_search {
  padding: 5px;
  border: none !important;
  height: 50px;
}
.header_search i {
  color: var(--primary);
  line-height: 1.7;
}
.social_icon1 {
  background-color: #bcbabb;
  width: 25px;
  height: 25px;
  font-size: 16px;
  line-height: 27px;
  border-radius: 50%;
  text-align: center;
}
.social_icon1.social_icon:hover {
  background-color: var(--primary);
  color: #fff;
}
.social_icon1.lin_color:hover {
  background-color: #0a66c2;
}
.social_icon1.tw_color:hover {
  background-color: #1da1f2;
}
.social_icon1.fb_color:hover {
  background-color: #3b5998;
}
.banner_spacer {
  height: 50px;
}
.banner_title {
  font-size: 70px;
  font-weight: 900;
}
.font_call {
  color: var(--primary);
  font-weight: 800;
}
.font1 {
  font-size: 32px;
  color: #a5a5a5;
}
.font2 {
  font-size: 14px;
  line-height: 2;
  color: #a5a5a5;
}
.text_dec:hover,
.text_dec:hover {
  text-decoration: none;
}
.font2 a {
  color: #a5a5a5;
}
.contact_icon {
  width: 35px;
  height: 35px;
  background-color: var(--secondary);
  border-radius: 30px;
  text-align: center;
  color: #fff;
  line-height: 35px;
  font-size: 20px;
}
.contact_form1 input,
.contact_form1 textarea {
  margin: 15px 0;
  background-color: #f7f7f7 !important;
  border: 1px solid #f7f7f7 !important;
}
.text_dec h6,
.text_dec p {
  font-size: 12px;
  color: #a5a5a5;
  line-height: 2;
}
.contact_form1 .btn-submit {
  float: right;
  color: #fff;
  background-color: var(--primary) !important;
  font-weight: 800;
  border-radius: 0;
  padding: 5px 30px;
}
.row_display {
  display: block;
}
.form_box {
  box-shadow: 0 0 10px 8px #f2f2f2;
  -webkit-box-shadow: 0 0 10px 8px #f2f2f2;
  -moz-box-shadow: 0 0 10px 8px #f2f2f2;
  padding: 40px 60px;
  border-radius: 10px;
  height: 100%;
}
@media (max-width: 767px) {
  .font1 {
    font-size: 27px;
  }
  .banner_title {
    font-size: 50px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  /* .header_search i {
    display: none;
  } */
  .search1 {
    width: 80%;
  }
}
/*About page css*/

.para_font {
  font-size: 15px;
}
.testimonial_bg {
  background-color: var(--secondary);
  padding: 30px 0;
}
.test_div {
  width: 60%;
}
.test_hr {
  border-top: 1px solid var(--secondary);
}
.test_img {
  max-width: 100px;
  margin: auto;
  height: 100px;
  object-fit: cover;
  border: 2px solid #fff;
}
.test_para {
  font-size: 13px;
  line-height: 2;
}
.test_para:before {
  content: "\f10d";
  font-family: "FontAwesome";
  font-weight: 900;
  font-size: 25px;
  padding-right: 12px;
  line-height: 1;
}
.test_para:after {
  content: "\f10e";
  font-family: "FontAwesome";
  font-weight: 900;
  font-size: 25px;
  padding-left: 12px;
  line-height: 1;
}
.test_div h5 {
  font-family: cursive;
  font-style: italic;
}

@media (max-width: 767px) {
  .test_div {
    width: 80%;
  }
}
@media (max-width: 425px) {
  .form_box {
    padding: 20px;
  }
}

/*****css for new login and register page******/

.form_login {
  margin: auto;
  max-width: 50%;
}
.form_login .input1 {
  font-size: 13px;
  padding: 14px 10px;
}
.form_login .otp_btn {
  background-color: var(--primary);
  color: #fff;
  padding: 10px 45px;
  border-radius: 0;
}
/* .form_login .otp_btn:hover {
  background-color: #485486;
  color: #fff;
} */
.form_login .fb_btn {
  color: #fff;
  background-color: #3b5999;
  border-radius: 0;
  padding: 15px 20px 15px 15px;
  border-bottom: 1px solid #254078;
}

.form_login .fa-facebook-f {
  padding: 9px 20px 15px 20px;
  background-color: #3b5999;
  font-size: 25px;
  color: #2a4478;
  border-right: 1px solid #2a4478;
  border-bottom: 1px solid #254078;
}
.form_login .fa-facebook-f:before,
.fa-google:before {
  vertical-align: sub;
}
.form_login .gm_btn {
  color: #fff;
  background-color: #f3553a;
  border-radius: 0;
  padding: 15px 50px 15px 15px;
  border-bottom: 1px solid #d1432f;
}
.form_login .fa-google {
  color: #ab2518;
  padding: 10px 20px 20px 20px;
  background-color: #f3553a;
  font-size: 25px;
  border-right: 1px solid #ab2518;
  border-bottom: 1px solid #d1432f;
}

@media (max-width: 767px) {
  .form_login {
    margin: auto;
    max-width: 100%;
  }
}
.tab_duration {
  display: flex;
  gap: 24px;
  align-items: center;
  justify-content: start;
  margin-bottom: 30px;
}
.tab_duration span {
  cursor: pointer;
}

.tab_duration_active {
  background-color: #0066ae;
  color: white;
  padding: 5px;
  border-radius: 50px;
  width: 77px;
  text-align: center;
}
.view_all_button {
  text-decoration: none;

  border: 1px solid var(--primary);
}
.view_all_button:hover {
  color: white;
  background-color: var(--primary);
}
.rating-grid {
  width: 100%;
  /* display: grid;
  grid-template-columns: repeat(2, 1fr); 
  gap: 20px;  */
}

.rating {
  display: flex; /* Enables flexbox */
  flex-direction: column; /* Stack the content vertically */
  align-items: center; /* Horizontally centers the content */
  justify-content: center; /* Vertically centers the content */
  padding: 12px;
  border-radius: 5px;
  text-align: center;
}

.rating-count {
  font-size: 0.8rem;
  font-weight: normal;
  margin-top: 5px; /* Adds space between the name and the count */
  background-color: #fff;
  padding: 2px 5px;
  border-radius: 10px;
  color: #333;
}

/* Bright colors for levels */
.platinum {
  background-color: #00bfff; /* Bright sky blue */
}

.gold {
  background-color: #ffdf00; /* Bright gold */
}

.silver {
  background-color: #e9eaec; /* Bright silver */
  color: black; /* Better contrast for silver */
}

.bronze {
  background-color: #e68729; /* Bright bronze */
}

.basic {
  background-color: #dcdcdc; /* Pale gray */
  color: black; /* Black text for better contrast */
}
